import { createAction } from 'redux-actions';

export const fetchAssessments = createAction('Assessments/FETCH');
export const fetchAssessmentsRequested = createAction('Assessments/FETCH_REQUESTED');
export const fetchAssessmentsSucceeded = createAction('Assessments/FETCH_SUCCEDED');
export const fetchAssessmentsFailed = createAction('Assessments/FETCH_FAILED');

export const createAssessments = createAction('createAssessments/FETCH', (payload, callback) => ({
  payload,
  callback,
}));
export const selectAssessment = createAction('selectAssessment/FETCH');
export const createAssessmentsRequested = createAction('createAssessments/FETCH_REQUESTED');
export const createAssessmentsSucceeded = createAction('createAssessments/FETCH_SUCCEDED');
export const createAssessmentsFailed = createAction('createAssessments/FETCH_FAILED');

export const submitFrs = createAction('submitFrs/FETCH', (payload, callback) => ({
  ...payload,
  callback,
}));
export const submitFrsRequested = createAction('submitFrs/FETCH_REQUESTED');
export const submitFrsSucceeded = createAction('submitFrs/FETCH_SUCCEDED');
export const submitFrsFailed = createAction('submitFrs/FETCH_FAILED');

export const fetchAssessment = createAction('Assessment/FETCH');
export const fetchAssessmentRequested = createAction('Assessment/FETCH_REQUESTED');
export const fetchAssessmentSucceeded = createAction('Assessment/FETCH_SUCCEDED');
export const fetchAssessmentFailed = createAction('Assessment/FETCH_FAILED');

export const deleteAssessmentRequested = createAction('Assessment/DELETE_REQUESTED');
export const deleteAssessmentSucceeded = createAction('Assessment/DELETE_SUCCEDED');
export const deleteAssessmentFailed = createAction('Assessment/DELETE_FAILED');

export const updateAssessmentRequested = createAction('Assessment/UPDATE_REQUESTED');
export const updateAssessmentSucceeded = createAction('Assessment/UPDATE_SUCCEDED');
export const updateAssessmentFailed = createAction('Assessment/UPDATE_FAILED');

export const updateFrQuestion = createAction('Question/UPDATE_FR', payload => payload);
export const updateHmQuestion = createAction('Question/UPDATE_HM', payload => payload);

export const cloneFrRequested = createAction('Assessment/CLONE_REQUESTED');
export const cloneFrSucceeded = createAction('Assessment/CLONE_SUCCEDED');
export const cloneFrFailed = createAction('Assessment/CLONE_FAILED');
