import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(243,243,243,0.05)',
  padding: 100,
  zIndex: 99,
};

const modalStyle = {
  position: 'fixed',
  zIndex: 100,
  left: 0,
  right: 0,
  top: 100,
  backgroundColor: '#fff',
  borderRadius: 5,
  maxWidth: 380,
  minHeight: 160,
  margin: '0 auto',
  padding: 20,
};


const InputModal = ({ content, children, removeBackDrop, showShadow }) => (
  <Fragment>
    <div style={modalStyle} className={showShadow ? 'shadow-lg p-3 mb-5 bg-white rounded' : null}>
      <div className="row">
        <div className="col-12 lead my-3 text-center">
          {content}
        </div>
      </div>
      <div className="row justify-content-between mt-3 border-top px-3 pb-0 pt-3">
        { children }
      </div>
    </div>
    <div style={backdropStyle} onClick={() => removeBackDrop()} role="presentation" />
  </Fragment>

);

InputModal.propTypes = {
  children: PropTypes.array,
  removeBackDrop: PropTypes.func,
  content: PropTypes.object,
  showShadow: PropTypes.bool,
};

InputModal.defaultProps = {
  children: [],
  removeBackDrop: () => {},
  content: {},
  showShadow: true,
};

export default InputModal;
