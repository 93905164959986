import watchAuthentication from '../auth/sagas';
import watchFetchChangelog from '../changelog/saga';
import {
  watchFetchAssessments,
  watchCreateAssessments,
  watchSubmitFrs,
  watchFetchAssessment,
  watchdeleteAssessments,
  watchUpdateAssessments,
  watchCloneFr,
} from '../assessment/saga';
import {
  watchSubmitFr,
  watchSubmitHm,
} from '../question/questionSaga';
import {
  watchFetchStructure,
  watchCreateStructure,
} from '../structure/saga';
import { all } from 'redux-saga/effects';
import { watchAuthFlow } from '../auth/sagas/authFlow';

const root = function* rootSaga() {
  yield all([
    watchFetchChangelog(),
    watchAuthentication(),
    watchFetchAssessments(),
    watchCreateAssessments(),
    watchSubmitFrs(),
    watchFetchAssessment(),
    watchFetchStructure(),
    watchCreateStructure(),
    watchdeleteAssessments(),
    watchUpdateAssessments(),
    watchCloneFr(),
    watchSubmitFr(),
    watchSubmitHm(),
    watchAuthFlow()
  ]);
};

export default root;
