import { call, all, put, takeEvery } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import * as actions from '../actions';
import rehydrateUserStore from './rehydrateUserStore';
import msalInstance from '../../../services/auth/msalHelpers';

export const action_creators = {
  signInFlow: createAction('signInFlow'),
  signOutFlow: createAction('signOutFlow'),
};

export function* signInFlow(userInfo) {
  yield put(actions.loginRequested());
  try {

    const profile = {
      userId: userInfo.payload.username,
      uniqueId: userInfo.payload.username,
      displayableId: userInfo.payload.name,
      identityProvider: userInfo.payload.tenantId,
    };

    yield put(actions.loginSucceeded({ ...profile }));
  } catch (ex) {
    yield put(actions.loginReset());
    throw ex;
  }
}

export function* signOutFlow() {
  yield put(actions.loginReset());
}

export function* watchAuthFlow() {
  yield all([
    takeEvery('signInFlow', signInFlow),
    takeEvery('signOutFlow', signOutFlow),

  ]);
}



