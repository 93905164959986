import React from 'react';
import PropTypes from 'prop-types';


const QuestionContainer = ({ children, support, margin }) => (
  <div>
    <div className="row mt-5 align-items-end">
      <div className="col-1 text-center">{ support ? 'Support Questions' : '' }</div>
      <div className="col-11">
        <div className="row">
          <div className={margin ? 'col-7 col-md-6' : 'col-7 col-md-8'} />
          <div className="col-5 col-md-4">
            <div className="row">
              <div className="col-2 bg-light text-center py-2" style={{ marginBottom: -20 }}>Weak</div>
              <div className="col-2 bg-light text-center py-2" style={{ marginBottom: -20 }}>Basic</div>
              <div className="col-2 bg-light text-center py-2" style={{ marginBottom: -20 }}>Strong</div>
              <div className="col-3 bg-light text-center py-2" style={{ marginBottom: -20 }}>Excellent</div>
              <div className="col-3 bg-light text-center py-2" style={{ marginBottom: -20 }}>No Answer</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {children}
  </div>
);

QuestionContainer.propTypes = {
  children: PropTypes.array,
  support: PropTypes.bool,
  margin: PropTypes.bool,
};

QuestionContainer.defaultProps = {
  children: [],
  support: false,
  margin: false,
};

export default QuestionContainer;
