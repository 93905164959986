import { combineReducers } from 'redux';
// @ts-ignore
import { routerReducer } from 'react-router-redux';
import authReducer from '../auth/reducer';
import manifestReducer from '../manifest';
import versionReducer from '../version/reducer';
import changelogReducer from '../changelog/reducer';
import assessment from '../assessment/reducer';
import question from '../question/questionReducer';
import structure from '../structure/reducer';
import { stateKeys } from '../../types';

const rootReducerConfig = {
  schemaVersion : manifestReducer,
  currentUser: authReducer,
  version: versionReducer,
  releaseNote: changelogReducer,
  routes: routerReducer,
  assessment: assessment,
  question: question,
  structure: structure,
};

export type StateKey = keyof typeof rootReducerConfig;

export const rootReducer = combineReducers(rootReducerConfig);
