import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../store';
import HeatMap from '../components/HeatMap';
import ProgressBar from '../components/common/atoms/ProgressBar';
import Modal from '../components/Modal';
import SubheaderTitle from '../components/common/atoms/SubheaderTitle';

class HeatMapPage extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    getAssesment: PropTypes.func.isRequired,
    submitAnswers: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object,
  };

  static defaultProps = {
    match: {},
    history: {},
    selectedAssessment: {},
  };

  state = {
    fr: {
      id: this.props.match.params.id,
      path: `Assessments/${this.props.match.params.id}/fr/${this.props.match.params.fr}/franswers`,
      data: {},
    },
    hm: {
      id: this.props.match.params.id,
      path: `Assessments/${this.props.match.params.id}/hmanswers`,
      data: {},
    },
    showModal: false,
    touched: false,
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAssesment(id);
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps({ selectedAssessment }) {
    // TODO: Refactor this into something that make sense
    const values = selectedAssessment.hmQuestions.reduce((init, item) => ({
      ...init,
      [item.id]: {
        questionId: item.id,
        answer: item.answer,
        rationalText: item.rationalText || '',
      },
    }), {});
    this.setState({
      hm: {
        ...this.state.hm,
        data: values,
      },
    });
  }

  handleOptionChange = (answer, rationText, item, obj) => {
    this.setState({
      [obj]: {
        ...this.state[obj],
        data: {
          ...this.state[obj].data,
          [item]: {
            questionId: item,
            answer: answer === 'NA' ? '' : answer,
            rationalText: answer === 'NA' ? '' : rationText,
          },
        },
      },
      touched: true,
    });
  }

  submitForms = () => {
    this.props.submitAnswers(this.state, () => {
      this.props.history.push(`/assessment/${this.props.match.params.id}`);
    });
  }

  leavePage = () => {
    if (this.state.touched) {
      this.setState({ showModal: this.state.touched });
    } else {
      this.props.history.push(`/assessment/${this.props.match.params.id}`);
    }
  }

  removeBackDrop = () => this.setState({ showModal: false })

  render() {
    const { selectedAssessment, match } = this.props;
    if (!Object.keys(this.state.hm.data).length) {
      return (
        <ProgressBar />
      );
    }

    return (
      <div className="container">
        { this.state.showModal ?
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
          >
            <Link to={`/assessment/${match.params.id}`} className="btn btn-primary-reverse border mr-3 px-4">Cancel</Link>
            <button
              type="button"
              className="btn btn-primary border px-4"
              onClick={() => this.submitForms()}
            >Save</button>
          </Modal>
          : null }
        <SubheaderTitle
          to={`/assessment/${match.params.id}`}
          text={'Heat map survey'}
        />
        <div className="row mt-3">
          <div className="col-12">
            <HeatMap
              questions={selectedAssessment.hmQuestions}
              selected={match.params.id}
              questionId={selectedAssessment.id}
              data={this.state.hm}
              handleOptionChange={(answer, rationT, item) =>
                this.handleOptionChange(answer, rationT, item, 'hm')}
            />
          </div>
        </div>
        <div className="row justify-content-end mt-3 mb-5">
          <button onClick={() => this.leavePage()} className="btn btn-primary-reverse border mr-3 px-4">Cancel</button>
          <button
            type="button"
            className="btn btn-primary border px-4"
            onClick={() => this.submitForms()}
          >Save</button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitAnswers: (value, callback) => dispatch(actions.submitFrs(value, callback)),
  getAssesment: id => dispatch(actions.fetchAssessment(id)),
});


const mapStateToProps = ({ assessment }, ownProps) => ({
  selectedAssessment: assessment[ownProps.match.params.id],
});

export default connect(mapStateToProps, mapDispatchToProps)(HeatMapPage);
