import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaCheckCircleO, FaEdit, FaPlus } from 'react-icons/lib/fa';

import CheckboxBootstrap from '../components/common/atoms/CheckboxBootstrap';
import Modal from '../components/InputModal';
import ProgressBar from '../components/common/atoms/ProgressBar';
import SubheaderTitle from '../components/common/atoms/SubheaderTitle';
import { getStructure } from '../store/structure';
import * as actions from '../store';


class Assessment extends Component {
  static propTypes = {
    selectedAssessment: PropTypes.object,
    match: PropTypes.object,
    getAssesment: PropTypes.func.isRequired,
    cloneItem: PropTypes.func.isRequired,
    updateFr: PropTypes.func.isRequired,
    currentUser: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selectedAssessment: {},
    match: {},
  };

  state = {
    isEditMode: false,
    selectedInputBoxes: {},
    deselect: true,
    showModal: false,
    sectionToCopyId: null,
    sectionToCopyName: '',
    newSectionName: '',
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAssesment(id);
    window.scrollTo(0, 0);
  }

  onAssessmentNameInputFocus() {
    if (this.state.newSectionName === '' || this.state.newSectionName === 'New section name') {
      this.setState({ newSectionName: '' });
    }
  }

  onAssessmentNameInputFocusOut() {
    if (this.state.newSectionName === '') {
      this.setState({ newSectionName: 'New section name' });
    }
  }

  onAssessmentNameInputChange(newSectionName) {
    this.setState({
      newSectionName,
    });
  }

  onHandleDeselect = () => {
    const obj = {};
    // eslint-disable-next-line
    this.props.selectedAssessment.fRs.map(item => obj[item.id] = !this.state.deselect);

    this.setState({
      selectedInputBoxes: {
        ...obj,
      },
      deselect: !this.state.deselect,
    });
  }

  setAssessmentNamingModalVisible = (id, name) => {
    this.setState({
      sectionToCopyId: id,
      sectionToCopyName: name,
      showModal: !this.state.showModal,
    });
  };

  dismissModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  tabHeader = (
    <div className="row justify-content-end">
      <div className="col-1 text-right lead">FR</div>
      <div className="col-3 text-right lead">Heat map</div>
    </div>
  );

  handleInputBoxes = (id, checked) => {
    this.setState({
      selectedInputBoxes: {
        ...this.state.selectedInputBoxes,
        [id]: checked,
      },
    });
  }

  editMode = () => {
    this.setState({
      isEditMode: true,
    });
  };

  submitChanges = () => {
    const oldVisibility = this.props.selectedAssessment.visibility;
    const newObj = {
      ...this.props.selectedAssessment,
      visibility: { ...oldVisibility, ...this.state.selectedInputBoxes },
    };

    this.props.updateFr(newObj);
    this.setState({ isEditMode: false });
  }

  cloneItem = (id) => {
    this.setAssessmentNamingModalVisible();
    this.props.cloneItem(id, this.props.selectedAssessment.id, this.state.newSectionName);
  }

  listItems = items =>
    items.filter(fr => (!fr.assessmentId || (fr.assessmentId === this.props.selectedAssessment.id)))
      .map((item, index) => (
      // eslint-disable-next-line
        <Fragment key={`${item.id}${index}`}>
          { this.state.showModal ?
            <Modal
              showShadow={false}
              content={this.renderAssessmentNameModal(this.state.sectionToCopyName)}
            >
              <button
                type="button"
                className="btn btn-lg px-5"
                onClick={() => this.dismissModal()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary btn-lg border px-5"
                onClick={() => this.cloneItem(this.state.sectionToCopyId)}
              >
                Save
              </button>
            </Modal>
            : null
          }
          {
            this.renderItems(item)
          }
        </Fragment>
      ));

  renderItems(item) {
    if (this.state.isEditMode) {
      return this.renderFR(item);
    }
    if (!(this.props.selectedAssessment.visibility[item.id] === false)) {
      return this.renderFR(item);
    }
    return null;
  }

  renderFR(item) {
    const checked = this.state.selectedInputBoxes[item.id] === undefined
      ? !(this.props.selectedAssessment.visibility[item.id] === false)
      : this.state.selectedInputBoxes[item.id];

    return (
      <div className="col-12 col-md-5 bg-light border border-primary py-2 mb-3 font-size-big">
        <div className="row align-items-center">
          <div className="col-8" style={{ fontSize: 16 }}>
            {this.state.isEditMode && (
              <span style={{ marginRight: '10px' }}>
                <CheckboxBootstrap
                  checked={checked}
                  id={item.id}
                  onChange={() => this.handleInputBoxes(item.id, !checked)}
                />
              </span>
            )}
            <Link
              to={{
                pathname: `/assessment/${this.props.selectedAssessment.id}/${item.id}`,
              }}
            >
              {item.name}
            </Link>
          </div>
          {this.state.isEditMode ? (
            <div className="col-4 pl-2 py-2 text-right" onClick={() => this.setAssessmentNamingModalVisible(item.id, item.name)} role="presentation">
              <FaPlus size={20} color={'#6c757d'} />
            </div>
          ) : (
            <Fragment>
              <div className="col-2 pl-2 py-2">
                <FaCheckCircleO size={30} color={item.isFrChecked ? '#28a745' : '#6c757d'} />
              </div>
              <div className="col-2 pl-2 text-center">
                <FaCheckCircleO size={30} color={item.isHmChecked ? '#28a745' : '#6c757d'} />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  renderAssessmentNameModal = (currentSection) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <p>You are copying section:</p>
        <p>{`"${currentSection}"`}</p>
        <p>Please enter a name for the new section</p>
        <input
          className="form-control"
          style={{ width: '300px', alignSelf: 'center' }}
          type="text"
          id="assessmentName"
          placeholder="New section name"
          onFocus={() => this.onAssessmentNameInputFocus(event.target.value)}
          onBlur={() => this.onAssessmentNameInputFocusOut(event.target.value)}
          onChange={event => this.onAssessmentNameInputChange(event.target.value)}
          value={this.state.newSectionName}
        />
      </div>
    );
  }

  heatmapsurvey() {
    const { selectedAssessment, structure, currentUser } = this.props;

    const { isEditMode, deselect } = this.state;
    const superUsers = structure.superUser;
    const user = currentUser.toLowerCase();

    const isSuperAdmin = superUsers.includes(user);
    const isAdmin = this.props.structure.structure && Object.values(structure.structure)
      .filter(item => (item.id === selectedAssessment.folderId && item.admin.includes(user)))
      .length > 0;

    if (selectedAssessment.fRs === undefined || selectedAssessment.fRs === null) {
      return <ProgressBar />;
    }

    if (selectedAssessment.fRs !== undefined || selectedAssessment.fRs != null) {
      return (
        <div className="container">
          <SubheaderTitle to="/" text={`${selectedAssessment.name.toUpperCase()}`} />
          <div className="row mt-4">
            <div className="col-6">
              {isEditMode ? (
                <div className="d-flex">
                  <input
                    type="checkbox"
                    className="form-check-input form-control-lg"
                    checked={deselect}
                    onChange={() => this.onHandleDeselect()}
                  />
                  <div className="text-secondary" style={{ fontSize: 20 }}>
                    { this.state.deselect ? 'Deselect all' : 'Select all' }
                  </div>
                </div>
              ) : (
                <Fragment>
                  <h4>MS Assessments</h4>
                  <div>Choose the area you are representing</div>
                </Fragment>
              )}
            </div>
            <div className="col-3 text-right">
              {(isSuperAdmin || isAdmin) &&
                !isEditMode && (
                <button
                  type="button"
                  className="btn btn-primary px-4 py-2"
                  style={{ fontSize: 16, position: 'relative' }}
                  onClick={() => this.editMode()}
                >
                  <FaEdit
                    size={16}
                    className="text-white"
                    style={{ position: 'absolute', top: 12, left: 6 }}
                  />
                    Edit assessment
                </button>
              )}
              {isEditMode && (
                <button
                  type="button"
                  className="btn btn-lg px-5 border rounded border-primary border-2"
                  onClick={() => this.setState({ isEditMode: false })}
                >
                  Cancel
                </button>
              )}
            </div>
            <div className="col-3 text-right">
              {isEditMode ? (
                <button
                  type="button"
                  className="btn btn-primary px-2 py-2"
                  style={{ fontSize: 16, position: 'relative' }}
                  onClick={() => this.submitChanges()}
                >
                  Submit changes
                </button>
              ) : (
                <Link
                  to={`/assessment/${this.props.selectedAssessment.id}/report`}
                  className="btn btn-primary px-4 py-2"
                  style={{ fontSize: 16 }}
                >
                  See results
                </Link>
              )}
            </div>
          </div>
          {!isEditMode ? (
            <div className="row justify-content-between mt-4">
              <div className="col-12 col-md-5">{this.tabHeader}</div>
              <div className="col-5 d-none d-md-block">{this.tabHeader}</div>
            </div>
          ) : (
            <div className="row justify-content-between my-3" />
          )}
          <div className="row justify-content-between mb-3">
            {selectedAssessment && selectedAssessment.fRs
              ? this.listItems(selectedAssessment.fRs)
              : null}
          </div>
          <hr />
          <div className="row justify-content-between">
            <div className="col-12 col-md-5 py-2 mb-5 mt-4 font-size-big HeatMap">
              <div className="row align-items-center">
                <div className="col-8" style={{ fontSize: 16 }}>
                  <Link
                    to={{
                      pathname: `/assessment/${selectedAssessment.id}/heatmap`,
                    }}
                  >
                    <div className="HeatMapText">General Heat map survey</div>
                  </Link>
                </div>
                <div className="col-2 pl-2">
                  <FaCheckCircleO
                    size={30}
                    color={selectedAssessment.hmQuestionsCount ? '#28a745' : '#6c757d'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      this.heatmapsurvey()
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getAssesment: id => dispatch(actions.fetchAssessment(id)),
  updateFr: assessment => dispatch(actions.updateAssessmentRequested({ ...assessment })),
  cloneItem: (frId, assessmentId, assessmentName) =>
    dispatch(actions.cloneFrRequested({ frId, assessmentId, assessmentName })),
});

const mapStateToProps = (state, ownProps) => ({
  selectedAssessment: state.assessment[ownProps.match.params.id],
  currentUser: state.currentUser.authStatus !== 'NOT_AUTHENTICATED' ? state.currentUser.user.uniqueId.split('@')[0] : "null",
  structure: getStructure(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
