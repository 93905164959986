export default {
  EQUINOR_PRIMARY: '#FF1243',
  EQUINOR_PROMINENT: '#007079',
  EQUINOR_SECONDARY: '#243746',
  EQUINOR_BLACK: '#000',
  EQUINOR_DISABLED: '#E6E5E6',
  EQUINOR_GRAY: '#666666',
  EQUINOR_LIGHT_GRAY: '#F2F2F2',
  EQUINOR_WHITE: '#FFF',

  COLOR_GRAY_GB: '#323232',
  COLOR_PINK: '#FE30A1',
  COLOR_LIGHT_BLUE: '#C2E2EE',
  COLOR_GREY_BACKGROUND: '#dfe1e3',
  COLOR_GREY_FOLDER: '#bdc1c7',
};
