import React from 'react';
import PropTypes from 'prop-types';

const backdropStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
  padding: 100,
  zIndex: 99,
};

const modalStyle = {
  backgroundColor: '#fff',
  borderRadius: 5,
  maxWidth: 400,
  minHeight: 240,
  margin: '0 auto',
  padding: 30,
};


const Modal = ({ children, removeBackDrop }) => (
  <div style={backdropStyle} onClick={() => removeBackDrop()} role="presentation">
    <div style={modalStyle}>
      <h3 className="border-bottom pb-3">Did you forget something?</h3>
      <div className="row">
        <div className="col-12 lead my-5">
          There are unsaved items in the form
        </div>
      </div>
      <div className="row justify-content-end mt-3 border-top pt-3">
        { children }
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.array || PropTypes.Object,
  removeBackDrop: PropTypes.func,
};

Modal.defaultProps = {
  children: [] || {},
  removeBackDrop: () => {},
};

export default Modal;
