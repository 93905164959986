import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Popover, PopoverBody, PopoverHeader, Tooltip } from 'reactstrap';
import { FaQuestionCircle } from 'react-icons/lib/fa';
import Radiobutton from '../components/common/atoms/Radiobutton';
import QuestionContainer from '../components/common/atoms/QuestionContainer';
import { requestTypes as status } from '../types';
const RadioButtons = ['weak', 'basic', 'strong', 'excellent', 'noAnswer'];

class HeatMap extends Component {
  static propTypes = {
    data: PropTypes.object,
    handleOptionChange: PropTypes.func.isRequired,
    onFocusOut: PropTypes.func.isRequired,
    questions: PropTypes.array,
  };

  static defaultProps = {
    data: {},
    questions: [],
  };

  state = {
    popoverOpen: false,
    tooltipOpen: false,
    startValue: '',
    toastId: shortid.generate(),
  }

  toggle = (item) => {
    this.props.questions.map((question) => {
      if (question.id === item) {
        this.setState({
          popoverOpen: {
            [item]: !this.state.popoverOpen[item],
          },
        });
      }
    });
  }

  toggleTooltip = (item) => {
    this.setState({
      tooltipOpen: {
        ...this.state.tooltipOpen,
        [item]: !this.state.tooltipOpen[item],
      },
    });
  }

  checkedOrNot = (value, answer, id) => {
    if (answer === value) return true;
    return this.props.data.data[id] ? this.props.data.data[id].answer === value : false;
  }

  handleOnFocus = (e) => {
    this.setState({ startValue: e.target.value });
  }

  handleSave(ctrl, radioValue, rationalText, questionId) {
    this.props.handleOptionChange(radioValue, rationalText, questionId);
    if (ctrl === 'textArea' && rationalText === this.state.startValue) {
      return;
    }
    this.props.onFocusOut('Hm', radioValue, rationalText, questionId, this.props.frId, this.state.toastId);
    toast.info('Saving your answer...', { toastId: this.state.toastId });
  }

  btnClick(desc, answer, item) {
    if (this.state.buttonId === event.target.id) {
      this.setState({ buttonId: 0 });
    } else {
      this.value = 'hidew description';
      this.setState({ buttonId: item.id });
    }
    this.setState({ description: !this.state.description });
  }

  renderToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    );
  };

  renderTextarea = (item, radioValue) => {
    if (this.state.buttonId !== item.id) {
      if (radioValue) {
        const answer = item.answers[this.props.data.data[item.id].answer];
        const text = this.props.data.data[item.id].rationalText;
  
        return (
          <div className="row justify-content-end" style={{ fontSize: 14, backgroundColor: '#F7F7F7' }}>
            <div className="col-6 py-2 px-3 border-right" style={{ color: 'black', marginBottom: '5px', marginTop: '5px' }}>
              {answer}
            </div>
            <div className="col-6 pr-0 pl-1">
              <textarea
                rows="3"
                className="form-control my-1"
                placeholder="Rational for score and proposed follow-up actions (optional)"
                value={text}
                onFocus={e => this.handleOnFocus(e)}
                onChange={e => this.props.handleOptionChange(radioValue, e.target.value, item.id)}
                onBlur={e => this.handleSave('textArea', radioValue, e.target.value, item.id)}
                disabled={false}
                style={{ fontSize: 14 }}
              />
            </div>
          </div>
        );
      }
    }
  }

  renderRadioButtons = (radioBtns, radioValue, rationalText, questionId) => radioBtns.map((item, index) => (
    <Radiobutton
      value={radioBtns[index]}
      checked={this.checkedOrNot(radioBtns[index], radioValue, questionId)}
      onChange={radioVal => this.handleSave('radioBtn', radioVal, rationalText, questionId)}
      disabled={radioValue !== null}
      key={index}
      width={index >= 3 ? 'col-3' : 'col-2'}
    />
  ));

  renderDescription = (desc, item, answer) => {
    if (this.state.buttonId === item.id) {
      return (
        <div className="row justify-content-end" style={{ fontSize: 14, backgroundColor: '#F7F7F7' }}>
          <div className="col-7 col-md-3 border-right" style={{ width: '25px', marginBottom: '10px' }}>
            <h6 style={{ marginTop: '10px' }}>Weak</h6>
            {item.answers.weak}
          </div>

          <div className="col-7 col-md-3 border-right" style={{ width: '25px', marginBottom: '10px' }}>
            <h6 style={{ marginTop: '10px' }}>Basic</h6>
            {item.answers.basic}
          </div>

          <div className="col-7 col-md-3 border-right" style={{ width: '25px', marginBottom: '10px' }}>
            <h6 style={{ marginTop: '10px' }}>Strong</h6>
            {item.answers.strong}
          </div>

          <div className="col-7 col-md-3 border-right" style={{ width: '25px', marginBottom: '10px' }}>
            <h6 style={{ marginTop: '10px' }}>Excellent</h6>
            {item.answers.excellent}
          </div>
        </div>
      );
    }
    return null;
  }

  renderSupportQuestions = items => items.map((item, index) => (
    <div key={`idx${index}`} className="col-12 mb-2" style={{ fontSize: 12 }}>{`# ${item}`}</div>
  ))

  renderQuestions = items => items.map((item) => {
    const answer = (this.props.data.data[item.id].answer) || '';
    const rationText = (this.props.data.data[item.id].rationalText) || '';
    const description = item.description.length ? ` - ${item.description}` : '';
    const title = `${item.count}: ${item.name}${description}`;
    return (
      <div key={item.id}>
        <div className="row mt-3">

          <div className="col-1 text-center border border-right-0 py-3" style={{ fontSize: 16 }}>
            <a id={`toolTip${item.id}`} style={{ marginRight: '25px' }}><FaQuestionCircle size={20} color="#219ad5" id={`popover${item.id}`} className="mr-2" onClick={() => this.toggle(item.id)} /></a>
            <Popover container="body" trigger="legacy" placement="left" isOpen={this.state.popoverOpen[item.id]} target={`popover${item.id}`}>
              <PopoverHeader>Support Questions

                <button type="button" className="btn btn-lg px-2" id={item.id} onClick={() => this.toggle(item.id)} style={{ fontSize: '20px', color: 'red', fontWeight: '200', marginLeft: '100px' }}>
                  {'x'}
                </button>
              </PopoverHeader>

              <PopoverBody>{this.renderSupportQuestions(item.supportingQuestions)}</PopoverBody>
            </Popover>
            <Tooltip placement="right" isOpen={this.state.tooltipOpen[item.id]} target={`toolTip${item.id}`} toggle={() => this.toggleTooltip(item.id)}>
              Support Questions
            </Tooltip>
          </div>
          <div className="col-11 border border-left-0 p-2">
            <div className="row align-items-center">
              <div className="col-7 col-md-6" style={{ fontSize: 16 }}>
                <span className="">{title}</span>
              </div>
              <div className="col-5 col-md-4" style={{ whiteSpace: 'nowrap' }}>
                <div className="row">
                  {this.renderRadioButtons(RadioButtons, answer, rationText, item.id)}
                </div>
              </div>
              <div className="col-5 col-md-1" style={{ whiteSpace: 'nowrap', width: '20px' }}>
                <button type="button" className="btn btn-lg px-2" id={item.id} onClick={() => this.btnClick(this.desc, answer, item)} style={{ fontSize: '12px', color: '#2D6E77', fontWeight: '600' }}>
                  {this.state.buttonId === item.id ? 'Hide description' : 'Show description'}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.renderTextarea(item, answer)}
        {this.renderDescription(this.state.description, item, answer)}


        <div className="row">
          {this.renderToast()}
        </div>
      </div>
    );
  });

  render() {
    return (
      <QuestionContainer 
        support
        margin
      >
        {this.renderQuestions(this.props.questions)}
      </QuestionContainer>
    );
  }
}

export default HeatMap;
