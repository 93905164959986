import config from 'app-config'; //eslint-disable-line
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);
export default msalInstance;

export async function getAccessToken(resource) {
  const account = msalInstance.getAllAccounts()[0];
  const scopes = resource === 'mad' ? config.Resources.mad.scopes : config.Resources.example.scopes;

  if (account) {
    const tokenResponse = await msalInstance.acquireTokenSilent({ account, scopes });
    return tokenResponse.accessToken;
  }
  throw new Error('No Active Account');
}
