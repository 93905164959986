import { createAction } from 'redux-actions';

export const submitFr = createAction('submitFr/SUBMIT');
export const submitFrRequested = createAction('submitFr/SUBMIT_REQUESTED');
export const submitFrSucceeded = createAction('submitFr/SUBMIT_SUCCEDED');
export const submitFrFailed = createAction('submitFr/SUBMIT_FAILED');

export const submitHm = createAction('submitHm/SUBMIT');
export const submitHmRequested = createAction('submitHm/SUBMIT_REQUESTED');
export const submitHmSucceeded = createAction('submitHm/SUBMIT_SUCCEDED');
export const submitHmFailed = createAction('submitHm/SUBMIT_FAILED');
