import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../store';
import SubheaderTitle from '../components/common/atoms/SubheaderTitle';
import TabControl from '../components/common/atoms/TabControl';
import ProgressBar from '../components/common/atoms/ProgressBar';
import Overview from '../components/Overview';
import Charts from '../components/Chart';
import PrintableReport from '../components/Report';

const scoreIndex = {
  weak: 1,
  basic: 2,
  strong: 3,
  excellent: 4,
  noAnswer: -1,
};


class ReportPage extends Component {
  static propTypes = {
    match: PropTypes.object,
    getAssesment: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object,
  };

  static defaultProps = {
    match: {},
    selectedAssessment: {},
  };

  state = {
    selectedAccordion: '',
    context: 'frQuestions',
    orderDesc: false,
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAssesment(id);
    window.scrollTo(0, 0);
  }

  accordionStateClick = (btnClicked) => {
    this.setState({ selectedAccordion: btnClicked === this.state.selectedAccordion ? '' : btnClicked });
  }

  renderOverview = (assessment, gHeatmap) => (
    <Overview
      key="9823"
      assessment={assessment}
      onClick={btnClicked => this.accordionStateClick(btnClicked)}
      generalHeatmap={gHeatmap}
      value={this.state.selectedAccordion}
      scoreIndex={scoreIndex}
      title={`Report ${this.props.selectedAssessment.name}`}
    />
  );

  renderChart = (assessment, generalHeatmap, scope, sort) => (
    <Charts
      key="9958"
      assessment={assessment}
      scoreIndex={scoreIndex}
      scope={scope}
      sort={sort}
      generalHeatmap={generalHeatmap}
      onChange={orderDesc => this.setState({ orderDesc })}
      onScopeChange={context => this.setState({ context })}
    />
  );

  renderReport = assessment => (
    <PrintableReport
      key="1497"
      assessment={assessment}
      assessmentName={this.props.selectedAssessment.name}
      scoreIndex={scoreIndex}
    />
  );


  render() {
    const { match, selectedAssessment } = this.props;
    if (!Object.keys(selectedAssessment).length) {
      return (
        <ProgressBar />
      );
    }
    const { context, orderDesc } = this.state;
    const { fRs: assessment, hmUsers: generalHeatmap, visibility } = selectedAssessment;

    let includedAssessment = assessment;
    if (visibility && Object.keys(visibility).length > 0) {
      includedAssessment = assessment.filter(item => visibility[item.id] !== false);
    }

    return (
      <div className="container">
        <SubheaderTitle
          to={`/assessment/${match.params.id}`}
          text={`Report ${selectedAssessment.name}`}
        />
        <TabControl
          titleOne="Overview"
          titleTwo="Chart"
          titleThree="Printable Report"
          tab3Id="PrintableReport"
          tabOne={[this.renderOverview(includedAssessment, generalHeatmap)]}
          tabTwo={[this.renderChart(includedAssessment, generalHeatmap, context, orderDesc)]}
          tabThree={[this.renderReport(includedAssessment)]}
          id="reportTab"
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAssesment: id => dispatch(actions.fetchAssessment(id)),
});


const mapStateToProps = ({ assessment }, ownProps) => ({
  selectedAssessment: assessment[ownProps.match.params.id],
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
