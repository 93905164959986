/**
 * This is just an example to get you started. You need to
 * set the proper config in the config/config.json file and
 * update this file as needed (or scrap it and make your own).
 */

import { NetworkException } from '../../utils/Exception';
import { getResource, getDefaultResourceName } from '../../settings';
import { getAccessToken } from '../auth/msalHelpers';

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const defaultResource = getDefaultResourceName();

async function sendRequest(request, resource) {
  return request(await getAccessToken(resource)).then(response => {
    if (response.ok) {
      if (response.status === 204) {
        return Promise.resolve({});
      }
      return response.json();
    }
    throw new NetworkException(response.statusText, response.status);
  });
}

function fetchJson(path, resource = defaultResource) {
  const url = `${getResource(resource).ApiBaseUrl}${path}`;
  const request = accessToken =>
    fetch(
      url,
      {
        method: 'GET',
        withCredentials: true,
        headers: {
          ...jsonHeaders,
          Authorization: `Bearer ${accessToken}`,
        },
      });

  return sendRequest(request, resource);
}

function submitData(data, path, method = 'PUT', resource = defaultResource) {
  const url = `${getResource(resource).ApiBaseUrl}${path}`;
  const request = accessToken =>
    fetch(
      url,
      {
        method,
        withCredentials: true,
        headers: {
          ...jsonHeaders,
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      });

  return sendRequest(request, resource);
}

function deleteAssessment(path, resource = defaultResource) {
  const url = `${getResource(resource).ApiBaseUrl}${path}`;
  const request = accessToken =>
    fetch(
      url,
      {
        method: 'DELETE',
        withCredentials: true,
        headers: {
          ...jsonHeaders,
          Authorization: `Bearer ${accessToken}`,
        },
      });

  return sendRequest(request, resource);
}

export default {
  getStructure: () => fetchJson('assessments/structure'),
  getAssessments: () => fetchJson('assessments'),
  getAssessment: id => fetchJson(`assessments/${id}`),
  deleteAssessment: id => deleteAssessment(`assessments/${id}`),
  createAssessment: data => submitData(data, 'assessments', 'POST'),
  createStructure: data => submitData(data, 'assessments/structure', 'POST'),
  submitFrs: (data, path) => submitData(data, path, 'POST'),
  cloneFr: id => submitData(id.assessmentName, `assessments/${id.assessment}/copyfr/${id.fr}`, 'POST'),
  submitFr: (data, path) => submitData(data, path, 'PUT'),
  submitHm: (data, path) => submitData(data, path, 'PUT'),
};
