/**
 * Not implemented and probably not a good idea anyway for a web app
 */

import {
  call,
} from 'redux-saga/effects';

const noop = () => Promise.resolve();

export default function* rehydrateUserStore(userId) {
  yield call(noop, userId);
}
