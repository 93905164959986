import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import {
  FaEllipsisV,
  FaFolder,
  FaUserPlus,
  FaAngleLeft,
  FaTrash,
  FaSearch,
  FaUser,
  FaMinusCircle,
} from 'react-icons/lib/fa';
import { Link } from 'react-router-dom';
import * as actions from '../store';
import Modal from '../components/InputModal';
import color from '../stylesheets/colors';

const defaultState = {
  contextMenuIsVisible: {},
  isRenameModalVisible: false,
  isDeleteModalVisible: false,
  isCreateFolder: false,
  isCreateSubFolder: false,
  isAddUser: false,
  isNewAssessmentOption: false,
  selectedFolder: null,
  subFolder: null,
  search: '',
  isDeleteModalVisibleAssessment: false,
  tempUser: [],
};

class AssessmentItems extends Component {
  static propTypes = {
    items: PropTypes.array,
  };
  static defaultProps = {
    items: [],
  };
  state = {
    ...defaultState,
    text: '',
  };

  onChangeText = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  setVisibleHandler = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      contextMenuIsVisible: {
        [id]: !this.state.contextMenuIsVisible[id],
      },
    });
  };

  removeBackDrop = () => this.setState({ ...defaultState });

  addUser = (event, item) => {
    const selectedItem = this.props.items.find(obj => obj.id === item.id).user || [];

    event.preventDefault();
    this.setState({
      isAddUser: true,
      assessmentToAddUser: item,
      tempUser: [...selectedItem],
    });
  };

  renameAssessment = (event, item) => {
    event.preventDefault();
    this.setState({
      isRenameModalVisible: true,
      assessmentToRename: item,
      search: item.name,
      name: item.name,
    });
  };

  deleteAssessment = (event, id) => {
    event.preventDefault();
    this.setState({
      isDeleteModalVisibleAssessment: true,
      assessmentToDelete: id,
    });
  };

  moveAssessment = (event, item) => {
    event.preventDefault();
    this.setState({
      isNewAssessmentOption: true,
      assessmentToMove: item,
    });
  };


  listItems = (items, admin, isSubAdmin) => {

    return _map(items, item => (
      <li key={item.id} className="list-group-item px-2">
        <Link
          to={`/assessment/${item.id}`}
          className="col-12 d-flex justify-content-between px-0 menu-structure"
        >
          <div className="col-6 px-0" style={{ fontSize: 16 }}>
            {item.name}
          </div>
          <div className="col-5 text-right px-0" style={{ fontSize: 16 }}>{`${moment(
            item.lastUpdated,
          ).format('DD.MM.YY')}`}</div>
          {(admin || isSubAdmin) ? (
            <Fragment>
              <div
                onClick={event => this.setVisibleHandler(event, `${item.id}`)}
                role="presentation"
                className="col-1 text-right"
              >
                <FaEllipsisV size={16} className="text-secondary" />
              </div>
              <div
                className={`border context-menu listItem ${this.state.contextMenuIsVisible[
                  `${item.id}`
                ] && 'isVisible'}`}
              >
                <div
                  className="context-menu-item text-center"
                  onClick={event => this.renameAssessment(event, item)}
                  role="presentation"
                >
                  Rename
                </div>
                <div
                  className="context-menu-item text-center"
                  onClick={event => this.moveAssessment(event, item)}
                  role="presentation"
                >
                  Move
                </div>
                <div
                  className="context-menu-item text-center"
                  onClick={event => this.addUser(event, item)}
                  role="presentation"
                >
                  Add user
                </div>
                <div
                  className="context-menu-item text-center bg-danger text-white"
                  onClick={event => this.deleteAssessment(event, item.id)}
                  role="presentation"
                >
                  Delete
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="col-1" />
          )}
        </Link>
      </li>
    ));
  }

  selectFolder = (event, folder) => {
    const { selectedFolder, subFolder } = this.state;
    event.stopPropagation();
    if (subFolder) {
      this.setState({
        selectedFolder: folder.id === (selectedFolder && selectedFolder.id) ? null : folder,
      });
    } else {
      this.setState({
        selectedFolder: folder.id === (selectedFolder && selectedFolder.id) ? null : folder,
        subFolder: folder.subFolders && !_isEmpty(folder.subFolders),
      });
    }
  };

  handleBackButton = (event) => {
    event.stopPropagation();
    this.setState({
      subFolder: false,
    });
  };

  handleTextChange = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  checkTempUserName(name) {
    if (name.includes('@')) {
      return name.substring(0, name.indexOf('@'));
    }
    return name;
  }

  addTempUser = () => {
    this.setState({
      tempUser: [...this.state.tempUser, this.checkTempUserName(this.state.name)],
      name: '',
    });
  }

  addUsersToAssessment = () => {
    this.props.addUsers({ ...this.state.assessmentToAddUser, user: this.state.tempUser });
    this.removeBackDrop();
  }

  removeUser = (item) => {
    const newObj = this.state.tempUser.filter(obj => obj !== item);
    this.setState({
      tempUser: newObj,
    });
  }

  renderModal = () => {
    const { structure } = this.props.structure;
    const { selectedFolder, subFolder, search } = this.state;
    const renderFolderLvl = subFolder ? selectedFolder.subFolders : structure;

    return (
      <div className="folder-modal-content">
        <div className="d-flex justify-content-between pb-2 border-bottom">
          {this.state.subFolder && (
            <span style={{ position: 'absolute', left: -16, top: -2 }}>
              <FaAngleLeft color={color.COLOR_GREY_GB} size={16} />
            </span>
          )}
          <div onClick={event => this.handleBackButton(event)} role="presentation">
            {this.state.selectedFolder ? this.state.selectedFolder.name : 'Move to folder'}
          </div>
          <input className="rounded" onChange={this.handleTextChange} value={search} />
          <FaSearch size={14} className="text-secondary folder-search" />
        </div>
        <div className="d-flex pt-3 row">
          {Object.values(renderFolderLvl)
            .filter(obj => obj.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            .map(folder => (
              <div
                className="col-2 px-1"
                onClick={event => this.selectFolder(event, folder)}
                role="presentation"
                key={folder.id}
              >
                <FaFolder
                  size={30}
                  className={
                    (selectedFolder && selectedFolder.id) !== folder.id
                      ? 'text-dark'
                      : 'text-primary'
                  }
                />
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 12 }}>
                  {folder.name}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    const { isSuperAdmin } = this.props;


    if (this.props.items === undefined || this.props.items.length === 0) {
      return false;
    }

    return (
      <Fragment>
        {this.state.isNewAssessmentOption ? (
          <Modal removeBackDrop={() => this.removeBackDrop()} content={this.renderModal()}>
            <button type="button" className="btn btn-lg px-5" onClick={() => this.removeBackDrop()}>
            Cancel
            </button>
            {this.state.selectedFolder && (
              <button
                type="button"
                className="btn btn-primary btn-lg border px-5"
                onClick={() => {
                  this.props.moveAssessment(this.state.assessmentToMove, this.state.selectedFolder);
                  this.removeBackDrop();
                }}
              >
              Save
              </button>
            )}
          </Modal>
        ) : null}
        {this.state.isRenameModalVisible ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <input
                value={this.state.name}
                className="rounded px-3 py-2 text-center"
                onChange={this.onChangeText}
              />
            }
          >
            <button type="button" className="btn btn-lg px-5" onClick={() => this.removeBackDrop()}>
            Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg border px-5"
              onClick={() => {
                this.props.renameAssessment(this.state.assessmentToRename, this.state.name);
                this.removeBackDrop();
              }}
              disabled={!this.state.name}
            >
            Save
            </button>
          </Modal>
        ) : null}
        {this.state.isDeleteModalVisibleAssessment ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <div className="d-flex justify-content-around">
                <div className="pt-3">
                  <FaTrash size={50} color={color.COLOR_GREY_BACKGROUND} />
                </div>
                <div>
                  <b>
                    <br /> Are you sure you want to delete Assessment? <br />This
                  cannot be undone!
                  </b>
                </div>
              </div>
            }
          >
            <button type="button" className="btn btn-lg px-5" onClick={() => this.removeBackDrop()}>
            Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger btn-lg border px-5"
              onClick={() => {
                this.props.deleteAssessment(this.state.assessmentToDelete);
                this.removeBackDrop();
              }}
            >
            Delete
            </button>
          </Modal>
        ) : null}
        {this.state.isAddUser ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <Fragment>
                <div className="d-flex border-bottom border-2 border-primary pb-2 mb-3">
                  <div className="text-primary col-4 text-right">
                    <FaUserPlus size="26" />
                  </div>
                  <div className="col-8 text-left">Access Control</div>
                </div>
                <div className="text-center">
                  <input
                    className="rounded text-center col-8 mb-3 p-2"
                    placeholder="Enter shortname"
                    value={this.state.name}
                    onChange={this.onChangeText}
                  />
                  <button
                    type="button"
                    className="btn btn-primary btn-lg border px-4 col-6"
                    onClick={() => this.addTempUser()}
                    disabled={!this.state.name}
                  >
                  Add User
                  </button>
                </div>
              </Fragment>
            }
          >
            <div
              className="col-12 border-bottom mb-3"
              style={{ height: 240, backgroundColor: color.COLOR_GREY_BACKGROUND, overflowY: 'auto' }}
            >
              { this.state.tempUser.map((item, index) => (
                // eslint-disable-next-line
                <div className="d-flex border-bottom justify-content-between align-items-center border-2 border-primary py-2 mb-3" >
                  <FaUser size="26" />
                  <div key={index}>{item}@equinor.com</div>
                  <FaMinusCircle color="red" size="26" onClick={() => this.removeUser(item)} />
                </div>
              ))}
            </div>
            <button type="button" className="btn btn-lg px-5 border" onClick={() => this.removeBackDrop()}>
            Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg border px-5"
              onClick={() => this.addUsersToAssessment()}
            >
            Save
            </button>
          </Modal>
        ) : null}
        <div className="row justify-content-between py-2" style={{ background: '#F2F2F2' }}>
          <div className="col-6 lead">Name</div>
          <div className="col-5 lead text-right">Last updated</div>
          <div className="col-1" />
        </div>
        <div className="row">
          <ul className="list-group list-group-flush col-12 p-0">{this.listItems(this.props.items, isSuperAdmin, this.props.isSubAdmin)}</ul>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteAssessment: id => dispatch(actions.deleteAssessmentRequested(id)),
  renameAssessment: (assessment, name) => dispatch(actions.updateAssessmentRequested({ ...assessment, name })),
  moveAssessment: (assessment, item) => dispatch(actions.updateAssessmentRequested({ ...assessment, folderId: item.id })),
  addUsers: assessment => dispatch(actions.updateAssessmentRequested({ ...assessment })),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentItems);
