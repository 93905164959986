import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleDown, FaAngleUp } from 'react-icons/lib/fa';

const Overview = ({ assessment, value, onClick, scoreIndex, generalHeatmap, title }) => {
  const calcAverage = (item) => {
    let frsCount = 0;
    let frsSum = 0;

    item.forEach((number) => {
      if (number.answer && number.answer !== 'noAnswer') {
        frsSum += scoreIndex[number.answer] || 0;
        frsCount += 1;
      }
    });
    return (frsSum / frsCount) || 0;
  };

  const tabHeader = (
    <div className="col-12" key="321123">
      <div className="row">
        <div className="col-3 offset-6 text-center text-primary" style={{ fontSize: 16 }}>FR Assessment</div>
        <div className="col-3 text-center text-primary" style={{ fontSize: 16 }}>Heat map survey</div>
      </div>
    </div>);

  const renderAnswers = items =>
    items.map((item) => {
      if (scoreIndex[item.answer]) {
        return (<div className="row border-top my-2 pt-2 pb-0" key={item.count}>
          <div className="col-1 text-center" style={{ fontSize: 16 }}>{item.count}</div>
          <div className="col-11 text-capitalize font-weight-bold" style={{ fontSize: 16 }}>{`${item.answer === 'noAnswer' ? 'No Answer' : item.answer} ( ${item.answer === 'noAnswer' ? '-' : scoreIndex[item.answer]} )`}</div>
          <div className="col-11 offset-1" style={{ fontSize: 14 }}>{item.rationalText}</div>
        </div>);
      }
      return null;
    },
    );

  const renderButton = (item, index, avg, prefix) => (
    <div className={`${item.id ? 'col-3' : 'col-3 offset-3'}`} style={{ fontSize: 16 }}>
      <button
        className={`btn ${value === `${prefix}${item.id || index}` ? 'btn-primary' : 'btn-inverse'} border rounded-0 border-primary col-12`}
        type="button"
        data-toggle="collapse"
        data-target={`#collapse${index}${prefix}`}
        aria-expanded="false"
        aria-controls={`collapse${index}${prefix}`}
        onClick={() => onClick(`${prefix}${item.id || index}`)}
      >
        <span className="pr-2" style={{ fontSize: 16 }}>{avg.toPrecision(2) }</span>
        { value === `${prefix}${item.id || index}` ? <FaAngleUp size={24} color="#ffffff" /> : <FaAngleDown size={24} color="#007079" /> }
      </button>
    </div>
  );


  const listItems = (items, fr = true) => items.map((item, index) => {
    const avgFrs = fr && calcAverage(item.frQuestions);
    const avgHms = calcAverage(item.hmQuestions);
    const clickTarget = item.userid ? (item.userid) : index;

    return (<div
      key={item.id || clickTarget}
      className="col-12 border-top border-primary py-2 font-size-big"
    >
      <div className="row align-items-center" id={`heading${clickTarget}`}>
        <div className="col-6" style={{ fontSize: 16 }}>
          {item.name || 'General Heatmap survey'}
        </div>
        {fr && renderButton(item, clickTarget, avgFrs, 'fr')}
        {renderButton(item, clickTarget, avgHms, 'hm')}
      </div>
      <div className="row">
        {fr &&
        <div className="collapse col-12" id={`collapse${clickTarget}fr`} aria-labelledby={`heading${clickTarget}`} data-parent="#accordion">
          {fr && renderAnswers(item.frQuestions)}
        </div>
        }
        <div className="collapse col-12" id={`collapse${clickTarget}hm`} aria-labelledby={`heading${clickTarget}`} data-parent="#accordion">
          {renderAnswers(item.hmQuestions)}
        </div>
      </div>
    </div>);
  });

  const listItemsGenearlHeatmap = heatmap => listItems(heatmap, false);

  return (
    <div>
      {tabHeader}
      <div className="accordion row" id="accordion">
        {listItemsGenearlHeatmap(generalHeatmap)}
        {listItems(assessment)}
      </div>
    </div>
  );
};

Overview.propTypes = {
  assessment: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  scoreIndex: PropTypes.object.isRequired,
  generalHeatmap: PropTypes.array,
  title: PropTypes.string.isRequired,
};

Overview.defaultProps = {
  assessment: [],
  generalHeatmap: [],
};

export default Overview;
