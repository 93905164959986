import { createMigrate, PersistConfig } from "redux-persist";
import { getStoredState } from "redux-persist";
import { manifest } from "../manifest";
const migration = createMigrate(manifest);
import storage from 'redux-persist/lib/storage';
import {StateKey} from "./rootReducer";

const whitelist = ["schemaVersion", "version"] satisfies StateKey[]
export const persistConfig = {
  key: "primary",
  storage: storage,
  migrate: migration,
  whitelist,
};
export const getUserPersistConfig = (userkey: string) => ({
  keyPrefix: `${userkey}_${"reduxPersist:"}`,
  storage: storage,
  migrate: migration,
  whitelist,
});
export const getPersistedState = (config: PersistConfig<any, any, any, any>) =>
  new Promise((resolve, reject) => {
    getStoredState(config);
  });
