import React from 'react';
import PropTypes from 'prop-types';

const Checked = {
  weak: 'bg-danger',
  basic: 'bg-warning',
  strong: 'bg-success',
  excellent: 'bg-primary',
  noAnswer: 'bg-secondary',
  none: 'bg-default',
};


const Radiobutton = ({ value, onChange, checked, width, disabled }) => (
  <div className={`form-check form-check-inline d-inline-block text-center mr-0 p-0 ${width}`}>
    <label
      className={`btn rounded-circle border border-dark pt-2 pl-0 ${checked ? Checked[value] : Checked.none}`}
      style={{ width: 42, height: 42, whiteSpace: 'nowrap', borderColor: '#2D6E77' }}
    >

      <div style={{ color: 'white', whiteSpace: 'nowrap', fontSize: '70', fontWeight: '900', width: '40px', height: '40px' }}>
        <h4 style={{ color: 'white' }}>✓</h4>
      </div>
      <input
        className="form-check-input mr-0"
        type="radio"
        value={value}
        checked={checked}
        onChange={e => onChange(e.target.value)}
        disabled={false}
        style={{ visibility: 'hidden', width: 10 }}
        onClick={() => (checked ? onChange('NA') : false)}
      />
    </label>
  </div>
);

Radiobutton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};

Radiobutton.defaultProps = {
  value: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  width: 'col-2',
};

export default Radiobutton;
