import { handleActions } from 'redux-actions';
import { requestTypes as status } from '../../types';
import * as actions from '../../store';

const defaultState = {
  isSavingQuestion: false,
  saveQuestionStatus: status.NONE,
};

export default handleActions({

  [actions.submitFrRequested]: state => ({
    ...state,
    saveQuestionStatus: status.REQUESTED,
    isSavingQuestion: true,
  }),
  [actions.submitFrSucceeded]: state => ({
    ...state,
    saveQuestionStatus: status.SUCCEEDED,
    isSavingQuestion: false,
  }),
  [actions.submitFrFailed]: state => ({
    ...state,
    saveQuestionStatus: status.FAILED,
    isSavingQuestion: false,
  }),

  [actions.submitHmRequested]: state => ({
    ...state,
    saveQuestionStatus: status.REQUESTED,
    isSavingQuestion: true,
  }),
  [actions.submitHmSucceeded]: state => ({
    ...state,
    saveQuestionStatus: status.SUCCEEDED,
    isSavingQuestion: false,
  }),
  [actions.submitHmFailed]: state => ({
    ...state,
    saveQuestionStatus: status.FAILED,
    isSavingQuestion: false,
  }),

}, defaultState);
