import { useEffect } from 'react';
import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  useAccount,
  useIsAuthenticated,
} from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { AppNavigator } from '../../navigation';
import AppHeader from '../../components/AppHeader';
import msalInstance from './msalHelpers';
import { useDispatch } from "react-redux";
import { action_creators } from '../../store/auth/sagas/authFlow';
import * as React from 'react';


const AuthHandler = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      instance.setActiveAccount(account);
      dispatch(action_creators.signInFlow(account));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === 'none') {
      instance.loginRedirect(loginRequest);
    }
  }, [isAuthenticated, inProgress]);

  return (
    <>
      <AuthenticatedTemplate>
        {account && <AppNavigator
            appHeader={() => <AppHeader userInfo={account} signOut={() => {
              instance.logout().then(r => dispatch(action_creators.signOutFlow()));
            }
            }/>}
          />}
      </AuthenticatedTemplate>
    </>
  );
};

const AuthenticationProvider = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthHandler />
    </MsalProvider>
  );
};

export default AuthenticationProvider;
export { msalInstance };
