import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { FaSearch, FaFolder, FaAngleLeft } from 'react-icons/lib/fa';
import Modal from '../components/InputModal';
import * as actions from '../store';
import { getStructure } from '../store/structure';
import color from '../stylesheets/colors';


const defaultState = {
  isNewAssessmentOption: false,
  selectedFolder: null,
  subFolder: null,
  noMorelvl: false,
  search: '',
};


class AssessmentCreate extends Component {
  static propTypes = {
    value: PropTypes.string,
    createAssessment: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  static defaultProps = {
    value: '',
  };

  state = {
    ...defaultState,
  }

  setAssessmentModalVisible = () => {
    this.setState({
      isNewAssessmentOption: true,
    });
  };

  removeBackDrop = () => this.setState({ ...defaultState });

  submitForms = () => {

  };

  selectFolder = (event, folder) => {
    const { selectedFolder } = this.state;

    const lastfolder = this.state.selectedFolder;
    event.stopPropagation();
    if (folder.subFolders) {
      this.setState({
        selectedFolder: folder.id === (selectedFolder && selectedFolder.id) ? null : folder,
        noMorelvl: false,
        lastFolder: lastfolder,
        subFolder: true,
      });
    } else {
      this.setState({
        selectedFolder: folder.id === (selectedFolder && selectedFolder.id) ? null : folder,
        subFolder: folder.subFolders && !_isEmpty(folder.subFolders),
        noMorelvl: true,
      });
    }
  }

  handleBackButton = (event) => {
    event.stopPropagation();
    this.setState({
      subFolder: false,
    });
  }

  handleTextChange = (event) => {
    this.setState({
      search: event.target.value,
    });
  }

  renderModal = (isSuperAdmin) => {
    const { structure } = this.props.structure;
    const { selectedFolder, subFolder, search } = this.state;
    let renderFolderLvl;

    if (this.state.noMorelvl) {
      renderFolderLvl = Object.values(this.state.lastFolder.subFolders);
    } else {
      renderFolderLvl = subFolder
        ? Object.values(selectedFolder.subFolders)
        : Object.values(structure)
          .filter(item => (isSuperAdmin || item.admin.includes(this.props.currentUser.toLowerCase())));
    }


    return (
      <div className="folder-modal-content">
        <div className="d-flex justify-content-between pb-2 border-bottom">
          { this.state.subFolder && (<span style={{ position: 'absolute', left: -16, top: -2 }}>
            <FaAngleLeft color={color.COLOR_GREY_GB} size={16} />
          </span>)}
          <div onClick={event => this.handleBackButton(event)} role="presentation">
            { this.state.selectedFolder ? this.state.selectedFolder.name : 'Save to folder' }
          </div>
          <input className="rounded" onChange={this.handleTextChange} value={search} />
          <FaSearch size={14} className="text-secondary folder-search" />
        </div>
        <div className="d-flex pt-3 row">
          { renderFolderLvl
            .filter(obj => obj.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            .map(folder => (
              <div className="col-2 px-1" onClick={event => this.selectFolder(event, folder)} role="presentation" key={folder.id} >
                <FaFolder size={30} className={(selectedFolder && selectedFolder.id) !== folder.id ? 'text-dark' : 'text-primary'} />
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 12 }}>{folder.name}</div>
              </div>
            )) }
        </div>
      </div>
    );
  }

  render() {
    const { createAssessment, value, onChange, structure, currentUser } = this.props;
    const superUsers = structure.superUser;
    const user = currentUser.toLowerCase();
    const admin = superUsers.includes(user);
    const subAdmin = structure.structure && (Object.values(structure.structure)
      .filter(item => item.admin.includes(user)).length > 0);

    if (!(admin || subAdmin)) return (<div className="p-5" />);

    return (
      <div className="row justify-content-center my-5 py-2">
        { this.state.isNewAssessmentOption ?
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={this.renderModal(admin)}
          >
            <button
              type="button"
              className="btn btn-lg px-5"
              onClick={() => this.removeBackDrop()}
            >Cancel</button>
            { this.state.selectedFolder &&
            <button
              type="button"
              className="btn btn-primary btn-lg border px-5"
              onClick={() => createAssessment(this.state.selectedFolder.id)}
            >Save</button>
            }
          </Modal>

          : null }
        <div className="col-12 text-center">
          <input
            placeholder="Insert Assesment name"
            className="border-top-0 border-left-0 border-right-0 text-center py-2"
            style={{ width: 300, fontSize: 16 }}
            value={value}
            onChange={event => onChange(event.target.value)}
          />
        </div>
        <div className="col-12 text-center mt-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.setAssessmentModalVisible()}
            disabled={!value}
            style={{ fontSize: 16 }}
          >
          Create new assessment
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateStructure: structure => dispatch(actions.createStructure(structure)),
});

const mapStateToProps = state => {
  return ({
    structure: getStructure(state),
    currentUser: state.currentUser.authStatus !== 'NOT_AUTHENTICATED' ? state.currentUser.user.uniqueId.split('@')[0] : "null",
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentCreate);

