import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Main from '../containers/MainPage';
import Assessment from '../containers/Assessment';
import Tabpage from '../containers/Tabpage';
import HeatMap from '../containers/HeatMapPage';
import ReportPage from '../containers/ReportPage';


export default () => (
  <Switch>
    <Route exact path="/assessment/:id/report" component={withRouter(ReportPage)} />
    <Route exact path="/assessment/:id/heatmap" component={withRouter(HeatMap)} />
    <Route exact path="/assessment/:id/:fr" component={withRouter(Tabpage)} />
    <Route exact path="/assessment/:id" component={withRouter(Assessment)} />
    <Route exact path="/" component={withRouter(Main)} />
    <Redirect to="/" />
  </Switch>
);
