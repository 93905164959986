import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import shortid from 'shortid';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

import {
  FaFolder,
  FaUserPlus,
  FaAngleLeft,
  FaTrash,
  FaSearch,
  FaUser,
  FaMinusCircle,
} from 'react-icons/lib/fa';
import * as actions from '../store';

import { getStructure } from '../store/structure';
import Modal from '../components/InputModal';
import Folder from '../components/common/atoms/folder';
import color from '../stylesheets/colors';
import AssessmentItems from './assessmentItems';
import { errortxt } from '../store/structure/reducer';

const defaultState = {
  contextMenuIsVisible: {},
  isRenameModalVisible: false,
  isDeleteModalVisible: false,
  isCreateFolder: false,
  isCreateSubFolder: false,
  isAddUser: false,
  isNewAssessmentOption: false,
  subFolder: null,
  search: '',
  isDeleteModalVisibleAssessment: false,
  tempUser: [],
};

const UPDATE = {
  DELETE: 'delete',
  RENAME: 'rename',
  CREATE: 'create',
};

class AssessmentList extends Component {
  static propTypes = {
    items: PropTypes.array,
  };
  static defaultProps = {
    items: [],
  };
  state = {
    ...defaultState,
    text: '',
  };

  onChangeText = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  setVisibleHandler = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      contextMenuIsVisible: {
        [id]: !this.state.contextMenuIsVisible[id],
      },
    });
  };

  firstFolders = (item) => {
    if (item.id !== this.state.submenuId) {
      this.setState({
        submenuId: item.id,
        selectedItem: item.id,
        subfolder: item.subFolders || null,
        contextMenuIsVisible: {},
      });
    } else {
      this.setState({
        submenuId: null,
        selectedItem: null,
        secondMenuId: null,
        thirdMenuId: null,
        contextMenuIsVisible: {},
      });
    }
  };

  secondFolders = (item) => {
    if (this.state.secondMenuId !== item.id) {
      this.setState({
        secondMenuId: item.id,
        selectedItem: item.id,
        contextMenuIsVisible: {},
      });
    } else {
      this.setState({
        selectedItem: this.state.submenuId,
        secondMenuId: null,
        thirdMenuId: null,
        contextMenuIsVisible: {},
      });
    }
  }

  thirdFolders = (item) => {
    this.setState({
      selectedItem: item.id,
      thirdMenuId: item.id,
      contextMenuIsVisible: {},
    });
  }

  removeBackDrop = () => this.setState({ ...defaultState });

  renameFolder = (event, id, name) => {
    event.stopPropagation();
    this.setState({
      isRenameModalVisible: true,
      action: UPDATE.RENAME,
      text: name,
      name,
      id,
    });
  };

  deleteFolder = (event, id) => {
    event.stopPropagation();
    this.setState({
      isDeleteModalVisible: true,
      action: UPDATE.DELETE,
      itemlvl: parent,
      id,
    });
  };

  createFolder = (event, subfolder) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isCreateFolder: true,
      action: UPDATE.CREATE,
      name: '',
      subfolder,
    });
  };

  addUser = (event, item, parent) => {
    const selectedItem = item.admin || [];
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      isAddUser: true,
      structureToAddUser: item,
      itemlvl: parent,
      tempUser: [...selectedItem],
    });
  };

  folderTemplate = (item, parent, handler, leftArrow, isSuperAdmin, isAdmin) => (
    <Folder
      key={item.id}
      selectedItem={this.state.selectedItem}
      item={item}
      parent={parent}
      leftArrow={leftArrow}
      setSubmenu={handler}
      setVisibleHandler={this.setVisibleHandler}
      renameFolder={this.renameFolder}
      addUser={this.addUser}
      deleteFolder={this.deleteFolder}
      contextMenu={this.state.contextMenuIsVisible}
      isSuperAdmin={isSuperAdmin}
      isAdmin={isAdmin}
    />)

  newFolderTemplate = submenuId => (
    <div key={submenuId} className="rounded py-1 px-2 d-flex justify-content-between align-items-center">
      <FaFolder key={submenuId} size={24} color={color.COLOR_GREY_FOLDER} />
      <div
        className="ml-1 text-secondary"
        onClick={event => this.createFolder(event, submenuId)}
        role="presentation"
      >
        { this.state.submenuId ? 'New subfolder' : 'New folder' }
      </div>
    </div>
  )

  createTopmenu = (structure = {}, isSuperAdmin = false, user) => {
    const { items: assessment } = this.props;
    let menuStructure;
    let folder;
    let newFolder;
    let Submenu1 = [];
    let Submenu2 = [];
    const subMenulvl = this.state.submenuId ? 'col-12 border-bottom mt-3 pb-2 px-0' : 'col-12 d-flex border-bottom mt-3 pb-2 px-0';

    if (assessment.length !== 0 && assessment !== undefined) {
      const assessmenUserAccess = assessment.map(item => item.folderId);

      menuStructure = (
        <div className={subMenulvl} style={{ fontSize: 14 }}>
          {
            (menuStructure = Object.values(structure)
              .filter(item => (isSuperAdmin
                || item.admin.includes(user)
                || assessmenUserAccess.includes(item.id)))
              .filter(items => (this.state.submenuId ? items.id === this.state.submenuId : items))
              .map((item, index, array) => {
                const isAdmin = item.admin.includes(user);
                folder = this.folderTemplate(item, 'first', this.firstFolders, item.id === this.state.submenuId, isSuperAdmin, isAdmin);

                if ((this.state.submenuId === item.id) && item.subFolders) {
                  Submenu1 = (
                    <div className="d-flex border-bottom mt-3 pb-2 px-0" style={{ fontSize: 14 }}>
                      {
                        (Object.values(item.subFolders)
                          .filter(secMenu => (this.state.secondMenuId ? secMenu.id === this.state.secondMenuId : secMenu))
                          .map((sub1) => {
                            const folder2 = this.folderTemplate(sub1, 'second', this.secondFolders, sub1.id === this.state.secondMenuId, isSuperAdmin, isAdmin);
                            if ((this.state.secondMenuId === sub1.id) && sub1.subFolders) {
                              Submenu2 = (
                                <div className="d-flex border-bottom mt-3 pb-2 px-0" style={{ fontSize: 14 }}>
                                  {
                                    (Object.values(sub1.subFolders)
                                      .map(sub2 => (this.folderTemplate(sub2, 'third', this.thirdFolders, false, isSuperAdmin, isAdmin))))
                                  }
                                  {
                                    this.newFolderTemplate(this.state.submenuId)
                                  }
                                </div>
                              );
                            }
                            return folder2;
                          }))}
                      {!this.state.secondMenuId &&
                        (isSuperAdmin || isAdmin) && this.newFolderTemplate(this.state.submenuId)
                      }
                    </div>
                  );
                }
                newFolder = !this.state.submenuId && (index === array.length - 1) && isSuperAdmin ? this.newFolderTemplate(this.state.submenuId) : null;
                return [folder, newFolder, Submenu1, Submenu2];
              },
              ))
          }
        </div>
      );
    } else {
      return (
        <p style={{ fontSize: '16px', width: '100%', textAlign: 'center' }}>You have not been added to any ongoing assessment,
          please ask your assessment facilitator for access</p>
      );
    }

    if (menuStructure.props.children.length === 0) {
      newFolder = isSuperAdmin && this.newFolderTemplate(this.state.submenuId);
      menuStructure = newFolder;
    }

    return menuStructure;
  };

  createMenuStructure = (structure, isSuperAdmin, user) => (
    <div className="row menu-structure">
      {this.createTopmenu(structure.structure, isSuperAdmin, user)}
    </div>
  )

  // TODO: Need to be remove and move into a reducer, old mockup code ... peevvv

  update = () => {
    const { structure } = this.props.structure;
    const { updateStructure } = this.props;
    const { action, text, name, id, submenuId, secondMenuId } = this.state;
    let newObj;

    const newFolder = `folder_${shortid.generate()}`;

    if (action === UPDATE.CREATE) {
      if (secondMenuId) {
        newObj = {
          ...this.props.structure,
          structure: {
            ...structure,
            [submenuId]: {
              ...structure[submenuId],
              subFolders: {
                ...structure[submenuId].subFolders,
                [secondMenuId]: {
                  ...structure[submenuId].subFolders[secondMenuId],
                  subFolders: {
                    ...structure[submenuId].subFolders[secondMenuId].subFolders,
                    [newFolder]: {
                      name,
                      id: newFolder,
                      assessment: [],
                      admin: [],
                    },
                  },
                },
              },
            },
          },
        };
      } else if (submenuId) {
        newObj = {
          ...this.props.structure,
          structure: {
            ...structure,
            [submenuId]: {
              ...structure[submenuId],
              subFolders: {
                ...structure[submenuId].subFolders,
                [newFolder]: {
                  name,
                  id: newFolder,
                  assessment: [],
                  admin: [],
                  subFolders: [],
                },
              },
            },
          },
        };
      } else {
        newObj = {
          ...this.props.structure,
          structure: {
            ...structure,
            [newFolder]: {
              name,
              id: newFolder,
              assessment: [],
              admin: [],
              subFolders: [],
            },
          },
        };
      }
    }

    if (action === UPDATE.RENAME) {
      newObj = !submenuId || id
        ? {
          ...this.props.structure,
          structure: {
            ...structure,
            [id]: {
              ...structure[id],
              name,
            },
          },
        }
        : {
          ...this.props.structure,
          structure: {
            ...structure,
            [submenuId]: {
              ...structure[submenuId],
              subFolders: {
                ...structure[submenuId].subFolders,
                [id]: {
                  ...structure[submenuId].subFolders[id],
                  name,
                },
              },
            },
          },
        };
    }

    if (action === UPDATE.DELETE) {
      const subs = submenuId ? _omit(structure[submenuId].subFolders, id) : null;

      if (submenuId === id) {
        this.setState({
          submenuId: null,
          selectedItem: null,
          secondMenuId: null,
          thirdMenuId: null,
          contextMenuIsVisible: {},
        })
      }
      newObj = !submenuId || (id === submenuId) ? {
          ...this.props.structure,
          structure: { ..._omit(structure, id) },
        } : {
        ...this.props.structure,
        structure: {
          ...structure,
          [submenuId]: {
            ...structure[submenuId],
            subFolders: {
              ...subs,
            },
          },
        },
      };
    }

    switch (this.state.action) {
      case UPDATE.DELETE:
        updateStructure({ ...newObj });
        this.removeBackDrop();
        break;
      case UPDATE.RENAME:
        if (text.trim() === name.trim()) return;
        updateStructure(newObj);
        this.removeBackDrop();
        break;
      case UPDATE.CREATE:
        updateStructure(newObj);
        this.removeBackDrop();
        break;
      case UPDATE.ADDUSER:
        updateStructure(newObj);
        this.removeBackDrop();
        break;
      default:
    }
  };

  selectFolder = (event, folder) => {
    const { selectedFolder, subFolder } = this.state;
    event.stopPropagation();
    if (subFolder) {
      this.setState({
        selectedFolder: folder.id === (selectedFolder && selectedFolder.id) ? null : folder,
      });
    } else {
      this.setState({
        selectedFolder: folder.id === (selectedFolder && selectedFolder.id) ? null : folder,
        subFolder: folder.subFolders && !_isEmpty(folder.subFolders),
      });
    }
  };

  handleBackButton = (event) => {
    event.stopPropagation();
    this.setState({
      subFolder: false,
    });
  };

  handleTextChange = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  checkTempUserName(name) {
    if (name.includes('@')) {
      return name.substring(0, name.indexOf('@'));
    }
    return name;
  }

  addTempUser = () => {
    this.setState({
      tempUser: [...this.state.tempUser, this.checkTempUserName(this.state.name)],
      name: '',
    });
  }

  removeUser = (item) => {
    const newObj = this.state.tempUser.filter(obj => obj !== item);
    this.setState({
      tempUser: newObj,
    });
  }

  adminToStructure = () => {
    let newStructure;

    if (this.state.itemlvl === 'first') {
      newStructure = {
        ...this.props.structure,
        structure: {
          ...this.props.structure.structure,
          [this.state.structureToAddUser.id]: {
            ...this.props.structure.structure[this.state.structureToAddUser.id],
            admin: this.state.tempUser,
          },
        },
      };
    } else {
      newStructure = {
        ...this.props.structure,
        structure: {
          ...this.props.structure.structure,
          [this.state.submenuId]: {
            ...this.props.structure.structure[this.state.submenuId],
            subFolders: {
              ...this.props.structure.structure[this.state.submenuId].subFolders,
              [this.state.structureToAddUser.id]: {
                ...this.props.structure.structure[this.state.submenuId].subFolders[this.state.structureToAddUser.id],
                admin: this.state.tempUser,
              },
            },
          },
        },
      };
    }

    this.props.updateStructure(newStructure);
    this.removeBackDrop();
  }

  handleTitle(items) {
    if (items !== undefined && items.length !== 0) {
      return (
        <h4>Assessments</h4>
      );
    }
    return null;
  }

  renderModal = () => {
    const { structure } = this.props.structure;
    const { selectedFolder, subFolder, search } = this.state;
    const renderFolderLvl = subFolder ? selectedFolder.subFolders : structure;

    return (
      <div className="folder-modal-content">
        <div className="d-flex justify-content-between pb-2 border-bottom">
          {this.state.subFolder && (
            <span style={{ position: 'absolute', left: -16, top: -2 }}>
              <FaAngleLeft color={color.COLOR_GREY_GB} size={16} />
            </span>
          )}
          <div onClick={event => this.handleBackButton(event)} role="presentation">
            {this.state.selectedFolder ? this.state.selectedFolder.name : 'Move to folder'}
          </div>
          <input className="rounded" onChange={this.handleTextChange} value={search} />
          <FaSearch size={14} className="text-secondary folder-search" />
        </div>
        <div className="d-flex pt-3 row">
          {Object.values(renderFolderLvl)
            .filter(obj => obj.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            .map(folder => (
              <div
                className="col-2 px-1"
                onClick={event => this.selectFolder(event, folder)}
                role="presentation"
                key={folder.id}
              >
                <FaFolder
                  size={30}
                  className={
                    (selectedFolder && selectedFolder.id) !== folder.id
                      ? 'text-dark'
                      : 'text-primary'
                  }
                />
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 12 }}>
                  {folder.name}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    const { structure, currentUser } = this.props;
    const superUsers = structure.superUser;
    const user = currentUser.toLowerCase();
    const isSuperAdmin = superUsers.includes(user);
    const subAdmin = !isSuperAdmin && this.props.structure.structure && Object.values(structure.structure).filter(item => item.admin.includes(user));
    const isSubAdmin = (subAdmin && subAdmin[0]) ? subAdmin[0].admin.includes(user) : false;

    let items = [];

    if (isSuperAdmin || (subAdmin && subAdmin.length > 0)) {
      if (this.state.selectedItem) {
        items = this.props.items.filter(item => (item.folderId === this.state.selectedItem));
      } else {
        items = this.props.items.filter(item => !item.folderId);
      }
    } else {
      items = this.props.items.length > 0 ? this.props.items.filter(item => item.user.includes(user)) : [];
    }

    if (items === undefined || items.length === 0) {
      console.log(this.props.error);
      if (this.props.error === 403 || this.props.error === 401 || this.props.error === 500) {
        return (
          <h3>Access denied</h3>
        );
      }
    }

    return (
      <div className="col-12">
        {this.state.isRenameModalVisible ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <input
                value={this.state.name}
                className="rounded px-3 py-2 text-center"
                onChange={this.onChangeText}
              />
            }
          >
            <button type="button" className="btn btn-lg px-5" onClick={() => this.removeBackDrop()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg border px-5"
              onClick={() => (this.state.assessmentToRename ? this.props.renameAssessment(this.state.assessmentToRename, this.state.name) : this.update())}
              disabled={!this.state.name}
            >
              Save
            </button>
          </Modal>
        ) : null}
        {this.state.isDeleteModalVisible ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <div className="d-flex justify-content-around">
                <div className="pt-3">
                  <FaTrash size={50} color={color.COLOR_GREY_BACKGROUND} />
                </div>
                <div>
                  <b>
                    This folder contains data.<br /> Are you sure you want to delete? <br />This
                    cannot be undone!
                  </b>
                </div>
              </div>
            }
          >
            <button type="button" className="btn btn-lg px-5" onClick={() => this.removeBackDrop()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger btn-lg border px-5"
              onClick={() => this.update()}
            >
              Delete
            </button>
          </Modal>
        ) : null}
        {this.state.isCreateFolder ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <input
                value={this.state.name}
                className="rounded px-3 py-2 text-center"
                placeholder="Folder name"
                onChange={this.onChangeText}
              />
            }
          >
            <button type="button" className="btn btn-lg px-5" onClick={() => this.removeBackDrop()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-lg border px-5"
              onClick={() => this.update()}
              disabled={!this.state.name}
            >
              Create
            </button>
          </Modal>
        ) : null}
        {this.state.isAddUser ? (
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
            content={
              <Fragment>
                <div className="d-flex border-bottom border-2 border-primary pb-2 mb-3">
                  <div className="text-primary col-4 text-right">
                    <FaUserPlus size="26" />
                  </div>
                  <div className="col-8 text-left">Access Control</div>
                </div>
                <div className="text-center">
                  <input
                    className="rounded text-center col-8 mb-3 p-2"
                    placeholder="Enter shortname"
                    value={this.state.name}
                    onChange={this.onChangeText}
                  />
                  <button
                    type="button"
                    className="btn btn-primary btn-lg border px-4 col-6"
                    onClick={() => this.addTempUser()}
                    disabled={!this.state.name}
                  >
                  Add User
                  </button>
                </div>
              </Fragment>
            }
          >
            <div
              className="col-12 border-bottom mb-3"
              style={{ height: 240, backgroundColor: color.COLOR_GREY_BACKGROUND, overflowY: 'auto' }}
            >
              { this.state.tempUser.map((item, index) => (
                // eslint-disable-next-line
                <div key={index} className="d-flex border-bottom justify-content-between align-items-center border-2 border-primary py-2 mb-3" >
                  <FaUser size="26" />
                  <div>{item}@equinor.com</div>
                  <FaMinusCircle color="red" size="26" onClick={() => this.removeUser(item)} />
                </div>
              ))}
            </div>
            <button type="button" className="btn btn-lg px-5 border" onClick={() => this.removeBackDrop()}>
            Cancel
            </button>
            <button type="button" className="btn btn-primary btn-lg border px-5" onClick={() => this.adminToStructure()}>
            Save
            </button>
          </Modal>
        ) : null}
        <div className="row">
          {this.handleTitle()}
        </div>
        {this.createMenuStructure(structure, isSuperAdmin, user)}

        <AssessmentItems items={items} isSuperAdmin={isSuperAdmin} isSubAdmin={isSubAdmin} structure={this.props.structure} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateStructure: structure => dispatch(actions.createStructure(structure)),
});

const mapStateToProps = state => ({
  structure: getStructure(state),
  error: errortxt(state),
  currentUser: state.currentUser.authStatus !== 'NOT_AUTHENTICATED' ? state.currentUser.user.uniqueId.split('@')[0] : "null",
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList);
