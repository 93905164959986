import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import _mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';

import * as actions from './actions';

const defaultState = {};

export default handleActions({
  [actions.fetchAssessmentsSucceeded]: (state, action) => (
    _mapKeys(action.payload, 'id')
  ),
  [actions.fetchAssessmentsRequested]: state => ({
    ...state,
  }),
  [actions.fetchAssessmentsFailed]: state => ({
    ...state,
  }),
  [actions.fetchAssessmentSucceeded]: (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  }),
  [actions.fetchAssessmentRequested]: state => ({
    ...state,
  }),
  [actions.fetchAssessmentFailed]: state => ({
    ...state,
  }),
  [actions.createAssessmentsSucceeded]: (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  }),
  [actions.createAssessmentsRequested]: state => ({
    ...state,
  }),
  [actions.createAssessmentsFailed]: state => ({
    ...state,
  }),
  [actions.deleteAssessmentRequested]: state => ({
    ...state,
  }),
  [actions.deleteAssessmentSucceeded]: (state, action) => ({
    ...omit(state, action.payload),
  }),
  [actions.deleteAssessmentFailed]: state => ({
    ...state,
  }),
  [actions.updateAssessmentRequested]: state => ({
    ...state,
  }),
  [actions.updateAssessmentSucceeded]: (state, action) => ({
    ...state,
    [action.payload.id]: {
      ...action.payload,
    },
  }),
  [actions.updateAssessmentFailed]: state => ({
    ...state,
  }),
  [actions.cloneFrRequested]: state => ({
    ...state,
  }),
  [actions.cloneFrSucceeded]: (state, action) => {

    return {
      ...state,
      [action.payload.assessmentId]: {
        ...state[action.payload.assessmentId],
        fRs: [...state[action.payload.assessmentId].fRs, action.payload.newFr],
      },
    };
  },
  [actions.cloneFrFailed]: state => ({
    ...state,
  }),

  [actions.updateFrQuestion]: (state, action) => {

    const frIdx = state[action.payload.assessmentId].fRs.findIndex(fr => fr.id === action.payload.frId);

    const newFRQs = state[action.payload.assessmentId].fRs[frIdx].frQuestions.map((q) => {
      if (q.id === action.payload.response.questionId) {
        return {
          ...q,
          answer: action.payload.response.answer,
          rationalText: action.payload.response.rationalText,
        };
      } return { ...q };
    });

    const newFrs = state[action.payload.assessmentId].fRs.map((fr) => {
      if (fr.id === action.payload.frId) {
        return {
          ...fr,
          frQuestions: newFRQs,
        };
      } return { ...fr };
    });

    return ({
      ...state,
      [action.payload.assessmentId]: {
        ...state[action.payload.assessmentId],
        fRs: newFrs,
      },
    });
  },


  [actions.updateHmQuestion]: (state, action) => {

    const frIdx = state[action.payload.assessmentId].fRs.findIndex(fr => fr.id === action.payload.frId);

    const newHmqs = state[action.payload.assessmentId].fRs[frIdx].hmQuestions.map((q) => {
      if (q.id === action.payload.response.questionId) {
        return {
          ...q,
          answer: action.payload.response.answer,
          rationalText: action.payload.response.rationalText,
        };
      } return { ...q };
    });

    const newFrs = state[action.payload.assessmentId].fRs.map((fr) => {
      if (fr.id === action.payload.frId) {
        return {
          ...fr,
          hmQuestions: newHmqs,
        };
      } return { ...fr };
    });

    return ({
      ...state,
      [action.payload.assessmentId]: {
        ...state[action.payload.assessmentId],
        fRs: newFrs,
      },
    });
  },

}, defaultState);

export const getAssessments = state => state.assessment || {};

export const getSelectedAssessment = state => state.assessment.selectedAssessment || {};

export const getAssessmentsAsList = createSelector([getAssessments], assessment => Object.values(assessment).sort((a, b) => (a.lastUpdated > b.lastUpdated ? -1 : 1)));

export const getFRsAsList = state => getSelectedAssessment(state).fRs || [];
