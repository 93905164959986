import {
  applyMiddleware,
  compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
// @ts-ignore
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
// @ts-ignore
import { routerMiddleware } from 'react-router-redux';
// @ts-ignore
import { createBrowserHistory } from 'history';
import { rootReducer } from './rootReducer';
import sagas from './rootSaga';
import { configureStore } from "@reduxjs/toolkit";
import { persistConfig } from './persistConfig';

export const history = createBrowserHistory();

const reactRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [];

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: [sagaMiddleware],
  devTools: true
});

if (process.env.NODE_ENV === 'development') {
  middlewares.push(reduxImmutableStateInvariant());
}

middlewares.push(sagaMiddleware, reactRouterMiddleware);


sagaMiddleware.run(sagas);

export const persistor = persistStore(store);

export default store;

