import React from 'react';
import PropTypes from 'prop-types';


const TabControl = ({ titleOne, titleTwo, tab3Id, titleThree, tabOne, tabTwo, tabThree, id }) => (
  <div className="row mt-3">
    <div className="col-12 mb-3">
      <div className="list-group flex-row" id={id} role="tablist">
        <a
          className="list-group-item list-group-item-action active col-2 rounded-0 mr-4 lead text-center"
          id={`list-${titleOne}-list`}
          data-toggle="list"
          href={`#list-${titleOne}`}
          role="tab"
          aria-controls={`${titleOne}`}
        >
          {titleOne}
        </a>
        <a
          className="list-group-item list-group-item-action col-2 rounded-0 lead text-center border-primary"
          id={`list-${titleTwo}-list`}
          data-toggle="list"
          href={`#list-${titleTwo}`}
          role="tab"
          aria-controls={`${titleTwo}`}
        >
          {titleTwo}
        </a>
        <a
          className="list-group-item list-group-item-action col-2 rounded-0 lead text-center border-primary"
          id={`list-${tab3Id}-list`}
          data-toggle="list"
          href={`#list-${tab3Id}`}
          role="tab"
          aria-controls={`${tab3Id}`}
        >
          {titleThree}
        </a>
      </div>
    </div>
    <div className="col-12">
      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id={`list-${titleOne}`} role="tabpanel" aria-labelledby={`list-${titleOne}-list`}>
          <div className="row justify-content-between mb-3">
            {tabOne}
          </div>
        </div>
        <div className="tab-pane fade" style={{ overflowX: 'auto', overflowY: 'hidden' }} id={`list-${titleTwo}`} role="tabpanel" aria-labelledby={`list-${titleTwo}-list`}>
          {tabTwo}
        </div>
        <div className="tab-pane fade" style={{ overflowX: 'auto', overflowY: 'hidden' }} id={`list-${tab3Id}`} role="tabpanel" aria-labelledby={`list-${tab3Id}-list`}>
          {tabThree}
        </div>
      </div>
    </div>
  </div>
);

TabControl.propTypes = {
  titleOne: PropTypes.string,
  titleTwo: PropTypes.string,
  titleThree: PropTypes.string,
  tab3Id: PropTypes.string,
  tabOne: PropTypes.array,
  tabTwo: PropTypes.array,
  tabThree: PropTypes.array,
  id: PropTypes.string.isRequired,
};

TabControl.defaultProps = {
  titleOne: '',
  titleTwo: '',
  titleThree: '',
  tab3Id: '',
  tabOne: [],
  tabTwo: [],
  tabThree: [],
};

export default TabControl;
