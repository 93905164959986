import {
  put,
  call,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../services/api';
import * as actions from '../../store';

function* submitFr(action) {
  try {
    yield put(actions.submitFrRequested());

    const response = yield call(api.default.submitFr, action.payload.data, action.payload.path);

    const data = {
      assessmentId: action.payload.id,
      frId: action.payload.frId,
      response,
    };

    toast.update(action.payload.data.toastid, { render: 'Answer saved!', type: toast.TYPE.SUCCESS, autoClose: 3000 });

    put(actions.submitFrSucceeded(data));
  } catch (ex) {
    toast.update(action.payload.data.toastid, { render: 'Failed!', type: toast.TYPE.ERROR, autoClose: 3000 });

    yield put(actions.submitFrFailed());
  }
}

function* submitHm(action) {
  try {
    yield put(actions.submitHmRequested());

    const response = yield call(api.default.submitHm, action.payload.data, action.payload.path);

    const data = {
      assessmentId: action.payload.id,
      frId: action.payload.frId,
      response,
    };

    toast.update(action.payload.data.toastid, { render: 'Answer saved!', type: toast.TYPE.SUCCESS, autoClose: 3000 });


    yield put(actions.submitHmSucceeded(data));

  } catch (ex) {
    toast.update(action.payload.data.toastid, { render: 'Failed!', type: toast.TYPE.ERROR, autoClose: 3000 });

    yield put(actions.submitHmFailed());
  }
}

export function* watchSubmitFr() {
  yield takeEvery(actions.submitFr.toString(), submitFr);
}

export function* watchSubmitHm() {
  yield takeEvery(actions.submitHm.toString(), submitHm);
}
