import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Checkbox from '../components/common/atoms/Checkbox';

const Chart = ({
  assessment,
  scoreIndex,
  scope,
  sort,
  onChange,
  onScopeChange,
  generalHeatmap,
  showHeader,
  showSort,
}) => {
  const fixData = items => (
    items.map((item) => {
      let frsCount = 0;
      let frsSum = 0;

      item[scope].forEach((number) => {
        if (number.answer && number.answer !== 'noAnswer') {
          frsSum += scoreIndex[number.answer] || 0;
          frsCount += 1;
        }
      });

      return {
        ...item,
        score: (frsSum / frsCount) || 0,
      };
    }));

  const checkData = () => {
    let data = assessment;
    if (scope === 'hmQuestions') {
      const heatMapData = fixData(generalHeatmap).map(item => ({ ...item, name: 'General Heatmap survey' }));

      data = [
        ...heatMapData,
        ...assessment,
      ];
    }
    return sort ? fixData(data).sort((a, b) => b.score - a.score) : fixData(data);
  };

  const chartHeader = () => (
    <div className="col-12 mb-4" key="123321">
      <div className="row no-gutters my-2">
        <div className="col-2">
          <Checkbox
            onChange={value => onChange(value.checked)}
            name="Sort by score"
            checked={sort}
          /> Sort by score
        </div>
        <div className="col-3 col-lg-2 offset-4 offset-lg-6 text-right">
          <button
            className={`btn ${scope === 'frQuestions' ? 'btn-primary' : 'btn-inverse border'} lead`}
            onClick={() => onScopeChange('frQuestions')}
          >FR Assessment</button>
        </div>
        <div className="col-3 col-lg-2 text-right">
          <button
            className={`btn ${scope === 'frQuestions' ? 'btn-inverse border' : 'btn-primary'} lead`}
            onClick={() => onScopeChange('hmQuestions')}
          >Heat map survey</button>
        </div>
      </div>
    </div>
  );

  const renderSort = () => (
    <div className="col-12 mb-4" key="123321">
      <div className="row no-gutters my-2">
        <div className="col-2">
          <Checkbox
            onChange={value => onChange(value.checked)}
            name="Sort by score"
            checked={sort}
          /> Sort by score
        </div>
      </div>
    </div>
  );

  const renderCharts = () => (
    <BarChart
      width={1024}
      height={600}
      data={checkData()}
      margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
      key="4819"
    >
      <XAxis
        dataKey="name"
        angle={-90}
        textAnchor="end"
        height={260}
        interval={0}
      />
      <YAxis type="number" domain={[0, 4]} />
      <Tooltip />
      <Legend />
      <Bar dataKey="score" fill="#007079" />
    </BarChart>
  );
  return (
    <div>
      {
        showHeader &&
        chartHeader()
      }
      {renderCharts()}
      <div className="col-12 mt-2 mb-2">*Questions with a value of &apos;No Answer&apos; on the assessment are excluded from the calculations for these scores. </div>
      <div>
        {
          showSort &&
          renderSort()
        }
      </div>
    </div>
  );
};

Chart.propTypes = {
  assessment: PropTypes.array,
  generalHeatmap: PropTypes.array,
  scope: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onScopeChange: PropTypes.func,
  scoreIndex: PropTypes.object.isRequired,
  sort: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool,
  showSort: PropTypes.bool,
};

Chart.defaultProps = {
  assessment: [],
  generalHeatmap: [],
  onChange: () => {},
  onScopeChange: () => {},
  showHeader: true,
  showSort: false,
};

export default Chart;
