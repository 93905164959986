import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../store';
import AssessmentList from '../components/AssessmentList';
import AssessmentCreate from '../components/AssessmentCreate';
import { getAssessmentsAsList } from '../store/assessment';

class MainPage extends Component {
  static propTypes = {
    getAssesments: PropTypes.func.isRequired,
    getStructure: PropTypes.func.isRequired,
    createAssessment: PropTypes.func.isRequired,
    assessments: PropTypes.array,
    history: PropTypes.object,
  };

  static defaultProps = {
    assessments: [],
    history: {},
  };

  state = {
    assessment: '',
  };

  componentDidMount() {
    this.props.getStructure();
    this.props.getAssesments();
  }

  submitNew = (folderId) => {
    this.props.createAssessment({ name: this.state.assessment, folderId }, (id) => {
      this.props.history.push(`/assessment/${id}`);
    });
  };

  render() {
    if (this.state.assessment == null) {
      return false;
    }
    return (
      <div className="container">
        <AssessmentCreate
          value={this.state.assessment}
          onChange={value => this.setState({ assessment: value })}
          createAssessment={folder => this.submitNew(folder)}
        />
        <div className="row">
          <AssessmentList items={this.props.assessments} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getStructure: () => dispatch(actions.fetchStructure()),
  getAssesments: () => dispatch(actions.fetchAssessments()),
  createAssessment: (value, callback) => dispatch(actions.createAssessments(value, callback)),
});

const mapStateToProps = state => ({
  assessments: getAssessmentsAsList(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
