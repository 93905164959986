import config from 'app-config'; //eslint-disable-line

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: config.AzureADClientId,
    authority: `https://login.microsoftonline.com/${config.AzureADTenantId}`,
    postLogoutRedirectUri: config.AppBaserUtl,
    redirectUri: config.AppBaserUtl,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: config.Resources.example.scopes,
};
