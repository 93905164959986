import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckedIcon from 'react-icons/lib/md/check-box';
import UncheckedIcon from 'react-icons/lib/md/check-box-outline-blank';
import colors from '../../../stylesheets/colors';

const styles = {
  defaultButtonStyle: {
    backgroundColor: 'rgba(0,0,0,0)',
    outline: 'none',
  },
};

export default class CheckboxBootstrap extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
  }

  static defaultProps = {
    disabled: false,
    style: {},
    size: 24,
    color: colors.EQUINOR_PROMINENT,
    tabIndex: 0,
    value: 'true',
    outline: 'none',
  }

  render() {
    const {
      disabled,
      tabIndex,
      onChange,
      name,
      checked,
      size,
      color, // eslint-disable-line
      value,
    } = this.props;

    return (
      <span
        className={['checkbox', disabled ? 'disabled' : ''].join(' ')}
        style={styles.defaultButtonStyle}
        role="button"
        tabIndex={disabled ? '' : tabIndex}
        onClick={() => !disabled && onChange({
          name,
          checked: !checked,
          value: checked && value,
        })}
      >
        { checked ? <CheckedIcon size={size} color={disabled ? colors.EQUINOR_DISABLED : color} />
          : <UncheckedIcon size={size} color={disabled ? colors.EQUINOR_DISABLED : color} />
        }
        {this.props.children}
      </span>
    );
  }
}

