import React from 'react';
import colors from '../../../stylesheets/colors';

const button = (props) => {
  const buttnClass = props.disabled === true ? 'btn' : 'btn btn-primary confirmbtn';
  let btnStyle = {
    textAlign: 'center',
    width: props.width ? props.width : '90%',
    fontSize: '14px',
    fontWeight: '500',
    margin: '12px 0 0 0',
    color: colors.EQUINOR_WHITE,
    backgroundColor: colors.EQUINOR_PROMINENT,
    borderColor: colors.EQUINOR_PROMINENT,
    borderRadius: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    textTransform: 'uppercase',
  };
  if (props.disabled === true) {
    btnStyle = { ...btnStyle, backgroundColor: '#808080A2', color: 'white' };
  }
  return (
    <button
      type="button"
      className={buttnClass}
      style={btnStyle}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default button;
