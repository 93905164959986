import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _mapKeys from 'lodash/mapKeys';
import * as actions from '../store';
import HeatMap from '../components/HeatMap';
import FunctionalRequirement from '../components/FunctionalRequirement';
import ProgressBar from '../components/common/atoms/ProgressBar';
import Modal from '../components/Modal';
import SubheaderTitle from '../components/common/atoms/SubheaderTitle';

class Tabpage extends Component {
  static propTypes = {
    selectedAssessment: PropTypes.object,
    getAssesment: PropTypes.func.isRequired,
    submitAnswers: PropTypes.func.isRequired,
    submitFr: PropTypes.func.isRequired,
    submitHm: PropTypes.func.isRequired,
    match: PropTypes.object,
    history: PropTypes.object,
  };

  static defaultProps = {
    selectedAssessment: {},
    match: {},
    history: {},

  };
  state = {
    fr: {
      id: this.props.match.params.id,
      path: `Assessments/${this.props.match.params.id}/fr/${this.props.match.params.fr}/franswers`,
      data: {},
    },
    hm: {
      id: this.props.match.params.id,
      path: `Assessments/${this.props.match.params.id}/fr/${this.props.match.params.fr}/hmanswers`,
      data: {},
    },
    showModal: false,
    touched: false,
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getAssesment(id);
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps({ selectedAssessment }) {
    // TODO: Refactor this into something that make sense

    const fr = _mapKeys(selectedAssessment.fRs, 'id')[this.props.match.params.fr];
    const hmValues = fr.hmQuestions.reduce((init, item) => ({
      ...init,
      [item.id]: {
        questionId: item.id,
        answer: item.answer || '',
        rationalText: item.rationalText || '',
      },
    }), {});

    const frValue = fr.frQuestions.reduce((init, item) => ({
      ...init,
      [item.id]: {
        questionId: item.id,
        answer: item.answer || '',
        rationalText: item.rationalText || '',
      },
    }), {});
    this.setState({
      hm: {
        ...this.state.hm,
        data: hmValues,
      },
      fr: {
        ...this.state.fr,
        data: frValue,
      },
    });
  }
  
  handleOptionChange = (answer, ratioText, item, obj) => {
    this.setState({
      [obj]: {
        ...this.state[obj],
        data: {
          ...this.state[obj].data,
          [item]: {
            questionId: item,
            answer: answer === 'NA' ? '' : answer,
            rationalText: answer === 'NA' ? '' : ratioText,
          },
        },
      },
      touched: true,
    });
  }

  submitQuestion = (type, radioValue, textValue, id, frId, toastid) => {
    let data = {};
    switch (type) {
      case 'Fr':
      case 'FrRadio':
        data = {
          id: this.props.match.params.id,
          path: `Assessments/${this.props.match.params.id}/fr/${this.props.match.params.fr}/franswers/${id}`,
          frId,
          data: {
            questionId: id,
            rationalText: this.state.fr.data[id].rationalText,
            answer: radioValue,
            toastid,
          },
        };
        this.props.submitFr(data);
        break;

      case 'Hm':
      case 'HmRadio':
        data = {
          id: this.props.match.params.id,
          path: `Assessments/${this.props.match.params.id}/fr/${this.props.match.params.fr}/hmanswers/${id}`,
          frId,
          data: {
            questionId: id,
            rationalText: textValue,
            answer: radioValue,
            toastid,
          },
        };
        this.props.submitHm(data);
        break;

      default:
        break;
    }
  };

  resetRequestState() {
    this.setState({ isAlreadyRequested: false });
  }

  submitForms = () => {
    this.props.submitAnswers(this.state, () => {
      this.props.history.push(`/assessment/${this.props.match.params.id}`);
    });
  }

  leavePage = () => {
    if (this.state.touched) {
      this.setState({ showModal: this.state.touched });
    } else {
      this.props.history.push(`/assessment/${this.props.match.params.id}`);
    }
  }

  removeBackDrop = () => this.setState({ showModal: false })

  render() {
    let funcReq;
    const { selectedAssessment } = this.props;
    if (selectedAssessment && selectedAssessment.fRs && Object.keys(this.state.hm.data).length) {
      const { fRs } = selectedAssessment;
      funcReq = _mapKeys(fRs, 'id')[this.props.match.params.fr];
    } else {
      return (
        <ProgressBar />
      );
    }

    return (
      <div className="container">
        { this.state.showModal ?
          <Modal
            removeBackDrop={() => this.removeBackDrop()}
          >
            <Link to={`/assessment/${this.props.match.params.id}`} className="btn btn-primary-reverse border mr-3 px-4">Cancel</Link>
            <button
              type="button"
              className="btn btn-primary border px-4"
              onClick={() => this.submitForms()}
            >Save</button>
          </Modal>
          : null }
        <SubheaderTitle
          to={`/assessment/${this.props.match.params.id}`}
          text={funcReq.name}
        />
        <div className="row mt-5">
          <div className="col-12 border-bottom border-primary">
            <div className="list-group flex-row offset-1 mb-1 " id="list-tab" role="tablist">
              <a
                className="list-group-item list-group-item-action active col-3 rounded-0 mr-2 lead"
                id="list-assessment-list"
                data-toggle="list"
                href="#list-assessment"
                role="tab"
                aria-controls="assessment"
              >
                FR Assessment
              </a>
              <a
                className="list-group-item list-group-item-action col-3 rounded-0 lead"
                id="list-heatmap-list"
                data-toggle="list"
                href="#list-heatmap"
                role="tab"
                aria-controls="heatmap"
              >
                  Heat map survey
              </a>
            </div>
          </div>
          <div className="col-12">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="list-assessment" role="tabpanel" aria-labelledby="list-assessment-list">
                <FunctionalRequirement
                  frId={funcReq.id}
                  questions={funcReq.frQuestions}
                  selected={this.props.match.params.id}
                  questionId={funcReq.id}
                  data={this.state.fr}
                  handleOptionChange={(answer, rationT, item) => this.handleOptionChange(answer, rationT, item, 'fr')}
                  onFocusOut={(type, answer, rationT, item, frId, toastid) => this.submitQuestion(type, answer, this.state.fr.data[item].rationalText, item, frId, toastid)}
                  saving={this.props.isSavingQuestion}
                  saveStatus={this.props.saveQuestionStatus}
                  radioValue
                />
              </div>
              <div className="tab-pane fade" id="list-heatmap" role="tabpanel" aria-labelledby="list-heatmap-list">
                <HeatMap
                  frId={funcReq.id}
                  questions={funcReq.hmQuestions}
                  selected={this.props.match.params.id}
                  questionId={funcReq.id}
                  data={this.state.hm}
                  handleOptionChange={(answer, rationT, item) => this.handleOptionChange(answer, rationT, item, 'hm')}
                  onFocusOut={(type, answer, rationT, item, frId, toastid) => this.submitQuestion(type, answer, rationT, item, frId, toastid)}
                  saving={this.props.isSavingQuestion}
                  saveStatus={this.props.saveQuestionStatus}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end mt-3 mb-5">
          <button onClick={() => this.leavePage()} className="btn btn-primary border px-4">
            Exit Edit Mode
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitAnswers: (value, callback) => dispatch(actions.submitFrs(value, callback)),
  submitFr: data => dispatch(actions.submitFr(data)),
  submitHm: data => dispatch(actions.submitHm(data)),
  getAssesment: id => dispatch(actions.fetchAssessment(id)),
});


const mapStateToProps = (state, ownProps) => ({
  selectedAssessment: state.assessment[ownProps.match.params.id],
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabpage);
