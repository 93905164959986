import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Radiobutton from '../components/common/atoms/Radiobutton';
import QuestionContainer from '../components/common/atoms/QuestionContainer';
import { requestTypes as status } from '../types';

const RadioButtons = ['weak', 'basic', 'strong', 'excellent', 'noAnswer'];

class FunctionalRequirement extends Component {
  static propTypes = {
    data: PropTypes.object,
    handleOptionChange: PropTypes.func.isRequired,
    onFocusOut: PropTypes.func.isRequired,
    questions: PropTypes.array,
  };

  static defaultProps = {
    data: {},
    questions: [],
  };

  state = {
    startValue: '',
    toastId: shortid.generate(),
  };

  checkedOrNot = (value, answer, id) => {
    if (answer === value) return true;
    return this.props.data.data[id] ? this.props.data.data[id].answer === value : false;
  }

  handleOnFocus = (e) => {
    this.setState({ startValue: e.target.value });
  }

  handleSave(ctrl, radioValue, rationalText, questionId) {
    this.props.handleOptionChange(radioValue, rationalText, questionId);
    if (ctrl === 'textArea' && rationalText === this.state.startValue) {
      return;
    }

    this.props.onFocusOut('Fr', radioValue, rationalText, questionId, this.props.frId, this.state.toastId);
    toast.info('Saving your answer...', { toastId: this.state.toastId });
  }
  resetState() {
    const initialState = {
      textValue: '',
      radioOption: '',
      frId: undefined,
    };
    this.setState(initialState);
  }

  handleOptionChange(ctrl, radioValue, value, frId) {
    if (ctrl === 'radioBtn') {
      this.handleSave('radioBtn', radioValue, value, frId);
    } else {
      this.setState({ textValue: value, radioOption: radioValue, frId, type: ctrl });
      this.handleSave('textArea', radioValue, value, frId);
    }
  }

  renderRadioButtons = (radioBtns, radioValue, rationalText, questionId) => radioBtns.map((item, index) => (
    <Radiobutton
      value={radioBtns[index]}
      checked={this.checkedOrNot(radioBtns[index], radioValue, questionId)}
      onChange={radioVal => this.handleOptionChange('radioBtn', radioVal, rationalText, questionId)}
      disabled={radioValue !== null}
      key={index}
      width={index >= 3 ? 'col-3' : 'col-2'}
    />
  ));

  renderTextarea = (item, radioValue) => {
    if (radioValue) {
      const text = this.props.data.data[item.id].rationalText;
      return (
        <div className="row justify-content-end">
          <div className="col-11 px-0">
            <textarea
              id="textinput"
              rows="3"
              className="form-control my-1"
              placeholder="Rational for score and proposed follow-up actions (optional)"
              value={text}
              onFocus={e => this.handleOnFocus(e)}
              onChange={e => this.props.handleOptionChange(radioValue, e.target.value, item.id)}
              onBlur={e => this.handleOptionChange('textArea', radioValue, e.target.value, item.id)}
              disabled={false}
              style={{ fontSize: 16 }}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderToast = () => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    );
  };

  renderQuestions = items => items.map((item) => {
    const answer = (this.props.data.data[item.id].answer) || '';
    const rationText = (this.props.data.data[item.id].rationalText) || '';

    return (
      <div key={item.id}>
        <div className="row mt-3">
          <div className="col-1 border border-right-0 text-center py-3" style={{ fontSize: 16 }}>
            {item.count}
          </div>
          <div className="col-11 border p-2">
            <div className="row align-items-center">
              <div className="col-7 col-md-8" style={{ fontSize: 16 }}>
                {item.description}
              </div>
              <div className="col-5 col-md-4" id="radio-buttons" >
                <div className="row">
                  {this.renderRadioButtons(RadioButtons, answer, rationText, item.id)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderTextarea(item, answer)}
        <div className="row">
          {this.renderToast()}
        </div>
      </div>
    );
  });

  render() {
    return (
      <span>
        <QuestionContainer>
          {this.renderQuestions(this.props.questions)}
        </QuestionContainer>
      </span>
    );
  }
}

export default FunctionalRequirement;
