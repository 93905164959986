import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import _mapKeys from 'lodash/mapKeys';


import * as actions from './actions';

const defaultState = {
  superUser: ['kjakr'],
  error: 'defaulterror',
};

export default handleActions({
  [actions.fetchStructureSucceeded]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [actions.fetchStructureRequested]: state => ({
    ...state,
  }),
  [actions.fetchStructureFailed]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [actions.createStructureSucceeded]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [actions.createStructureRequested]: state => ({
    ...state,
  }),
  [actions.createStructureFailed]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
}, defaultState);

export const getStructure = state => state.structure || {};
export const errortxt = state => state.structure.error || {};
