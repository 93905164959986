import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import api from '../../services/api';
import * as actions from './actions';


function* fetchStructure() {
  try {
    yield put(actions.fetchStructureRequested());
    const response = yield call(api.default.getStructure);
    yield put(actions.fetchStructureSucceeded(response));
  } catch (ex) {
    yield put(actions.fetchStructureFailed(ex.status));
  }
}

function* createStructure(action) {
  try {
    yield put(actions.createStructureRequested());
    yield call(api.default.createStructure, action.payload);
    yield put(actions.createStructureSucceeded(action.payload));
  } catch (ex) {
    yield put(actions.createStructureFailed());
  }
}

export function* watchFetchStructure() {
  yield takeLatest(actions.fetchStructure.toString(), fetchStructure);
}

export function* watchCreateStructure() {
  yield takeLatest(actions.createStructure.toString(), createStructure);
}
