import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserIcon from 'react-icons/lib/io/android-person';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import brandLogo from '../../resources/images/equinor-logo.png';
import { getAppVersion } from '../settings';

export default class AppHeader extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.state = {
      menuIsOpen: false,
    };
  }

  toggleMenu() {
    this.setState(prevState => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  }

  toggleDropdown(key) {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }));
  }

  statuscheck() {
    if (this.props.userInfo.name == null) {
      return false;
    }
    return true;
  }

  render() {
    const obj = this.statuscheck();
    if (!obj) {
      return false;
    }
    return (
      <div className="navbar navbar-dark navbar-expand-md app-header">
        <span className="navbar-brand">
          <div className="brand">
            <Link to="/">
              <img className="brand-logo" alt="Equinor" src={brandLogo} />
            </Link>
            <span className="divider" />
            <div className="d-inline-block nav-item">MS Assessments</div>
            <div className="d-inline-block nav-item" style={{ marginLeft: '100px' }}>For Internal Use Only</div>
            <span className="loader">
              <Loader type="ball-pulse" active={false} />
            </span>
          </div>
        </span>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#header-menu" aria-controls="header-menu" aria-expanded="false">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="header-menu">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="menu-item">
                  <UserIcon size={18} />
                  <span>
                    {this.props.userInfo.name}
                  </span>
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dark" aria-labelledby="userDropdown">
                <div className="dropdown-header">
                  <div>{this.props.userInfo?.username}</div>
                  <div>version: {getAppVersion()}</div>
                </div>
                <div className="dropdown-divider" />
                <a tabIndex={0} href="#" className="dropdown-item" role="menuitem" onClick={() => this.props.signOut()}>Sign out</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
