import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../store';
import { getVersion } from '../store/version';
import { getAppVersion } from '../settings';
import { getCurrentUser } from '../store/auth';
import { ErrorBoundary } from '../components/common';
import AuthenticationProvider from '../services/auth/AuthenticationProvider';


class App extends React.Component {
  static propTypes = {
    version: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    setVersion: PropTypes.func.isRequired,
  };

  static defaultProps = {
    version: null,
  };

  componentDidMount() {
    const appVersion = getAppVersion();
    if (this.props.version !== appVersion) {
      this.props.setVersion(appVersion);
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <AuthenticationProvider />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state) || {},
  version: getVersion(state),
});

const mapDispatchToProps = dispatch => ({
  setVersion: version => dispatch(actions.setVersion(version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
