import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/lib/fa';


const SubheaderTitle = ({ to, text }) => (
  <div className="row">
    <div className="col-12 pt-2 pb-1 bg-light">
      <div className="row">
        <div className="lead col-2">
          <Link to={to} className="btn btn-light">
            <FaArrowLeft size={20} color="#6c757d" />
          </Link>
        </div>
        <h3 className="col-8 p-2 text-center">{text}</h3>
      </div>
    </div>
  </div>
);

SubheaderTitle.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
};

SubheaderTitle.defaultProps = {
  text: '',
};

export default SubheaderTitle;
