import { createAction } from 'redux-actions';

export const fetchStructure = createAction('Structure/FETCH');
export const fetchStructureRequested = createAction('Structure/FETCH_REQUESTED');
export const fetchStructureSucceeded = createAction('Structure/FETCH_SUCCEDED');
export const fetchStructureFailed = createAction('Structure/FETCH_FAILED');

export const createStructure = createAction('createStructure/FETCH');
export const createStructureRequested = createAction('createStructure/FETCH_REQUESTED');
export const createStructureSucceeded = createAction('createStructure/FETCH_SUCCEDED');
export const createStructureFailed = createAction('createStructure/FETCH_FAILED');
