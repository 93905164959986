import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import ReactToPrint from 'react-to-print';

import Button from '../components/common/atoms/Button';
import Charts from '../components/Chart';
import Radiobutton from './common/atoms/Radiobutton';

class Report extends Component {

  static propTypes = {
    assessment: PropTypes.array,
  };

  static defaultProps = {
    assessment: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      frOrder: false,
      hmOrder: false,
      printBtnText: 'Print to PDF',
    };
  }

  renderFrChart() { 
    return (
      <div id="frChart">
        <div style={{ marginBottom: '50px' }}>
          <h2>FR Assessment - Chart</h2>
        </div>

        <div>
          <Charts
            key="1949"
            assessment={this.props.assessment}
            scoreIndex={this.props.scoreIndex}
            scope={'frQuestions'}
            sort={this.state.frOrder}
            generalHeatmap={this.props.generalHeatmap}
            showHeader={false}
            showSort
            onChange={frOrder => this.setState({ frOrder })}
          />
        </div>
      </div>
    );
  }

  renderFrSurvey(sections) {
    return (
      <div id="frSurvey" style={{ marginTop: '100px' }}>
        <div style={{ marginBottom: '40px' }}>
          <h2>FR Assessment - Survey</h2>
        </div>
        {this.renderLegend()}
        {
          sections.map((section, index) => {
            return (
              <div key={shortid.generate()}>
                {
                  <table className="table" style={{ marginBottom: '0px' }}>
                    <colgroup>
                      <col width="5%" />
                      <col width="95%" />
                    </colgroup>
                    <thead >
                      <tr>
                        <th style={{ paddingTop: '30px', paddingBottom: '20px' }}><h3>{index + 1}</h3></th>
                        <th style={{ paddingTop: '30px', paddingBottom: '20px' }}><h3>{section.name}</h3></th>
                      </tr>
                    </thead>
                  </table>
                }
                <div>
                  {
                    this.renderFrQuestions(section.frQuestions)
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderFrQuestions(questions) {
    return (
      questions.map((question) => {
        return (
          <div key={shortid.generate()}>
            <div>
              <table className="table" style={{ marginBottom: '0px' }}>
                <colgroup>
                  <col width="5%" />
                  <col width="95%" />
                </colgroup>
                <tbody>
                  {this.renderRow(question.count, question.description, true)}
                  {this.renderRow(<Radiobutton value={question.answer ? question.answer : ''} checked />, question.rationalText ? question.rationalText : 'No Answer provided.')}
                </tbody>
              </table>
            </div>
          </div>
        );
      })
    );
  }

  renderHeatmapChart() {
    return (
      <div id="hmChart" style={{ marginTop: '100px' }}>
        <div style={{ marginBottom: '50px' }}>
          <h2>Heatmap Assessment - Chart</h2>
        </div>

        <div>
          <Charts
            key="1976"
            assessment={this.props.assessment}
            scoreIndex={this.props.scoreIndex}
            scope={'hmQuestions'}
            sort={this.state.hmOrder}
            generalHeatmap={this.props.generalHeatmap}
            showHeader={false}
            showSort
            onChange={hmOrder => this.setState({ hmOrder })}
          />
        </div>
      </div>
    );
  }

  renderHeatmapSurvey(sections) {
    return (
      <div id="hmSurvey" style={{ marginTop: '100px', marginBottom: '50px' }}>
        <div style={{ marginBottom: '40px' }}>
          <h2>Heatmap Assessment - Survey</h2>
        </div>
        {this.renderLegend()}
        {
          sections.map((section, index) => {
            return (
              <div key={shortid.generate()}>
                {
                  <table className="table" style={{ marginBottom: '0px' }} >
                    <colgroup>
                      <col width="5%" />
                      <col width="95%" />
                    </colgroup>
                    <thead >
                      <tr>
                        <th style={{ paddingTop: '30px', paddingBottom: '20px' }}><h3>{index + 1}</h3></th>
                        <th style={{ paddingTop: '30px', paddingBottom: '20px' }}><h3>{section.name}</h3></th>
                      </tr>
                    </thead>
                  </table>
                }
                <div>
                  {
                    this.renderHeatmapQuestions(section.hmQuestions)
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderHeatmapQuestions(questions) {
    return (
      questions.map((question) => {

        const row1 = (
          <div>
            <div style={{ fontWeight: '700', fontSize: '18px' }}>{`${question.count} - ${question.description}`}</div>
            <p style={{ fontWeight: '700', fontSize: '14px', marginLeft: '25px' }}>(Support Questions)</p>
            <div>
              {
                question.supportingQuestions.map(supportQuestion => <p key={shortid.generate()} style={{ paddingLeft: '70px' }}>{supportQuestion}</p>)
              }
            </div>
          </div>
        );

        const answer = this.getAnswer(question.answer, question.rationalText);
        const answerExplanation = this.getAnswerExplanation(question.answer, question.answers);

        const row2 = (
          <div>
            <p style={{ fontWeight: '700', fontSize: '14px' }}>Justification Provided:</p>
            <p>{answer}</p>
            <p style={{ fontWeight: '700', fontSize: '14px' }}>Answer Explanation:</p>
            <p>{answerExplanation}</p>
          </div>
        );

        return (
          <div key={shortid.generate()}>
            <div>
              <table className="table" style={{ marginBottom: '0px' }}>
                <colgroup>
                  <col width="5%" />
                  <col width="95%" />
                </colgroup>
                <tbody>
                  {this.renderHmRow(row1)}
                  {this.renderRow(<Radiobutton value={question.answer ? question.answer : ''} checked />, row2)}
                </tbody>
              </table>
            </div>
          </div>
        );
      })
    );
  }

  renderHmRow(elm1) {
    return (
      <tr style={{ marginBottom: '0px' }}>
        <td colSpan={2}>{elm1}</td>
      </tr>
    );
  }

  renderRow(elm1 = <span />, elm2, bold = false) {
    const fontWeight = bold ? '700' : '400';
    return (
      <tr style={{ fontWeight, marginBottom: '0px' }}>
        <td>{elm1}</td>
        <td>{elm2}</td>
      </tr>
    );
  }

  getAnswer(answer, text) {
    // question was answered (checkbox selected) and justification provided
    // need the null checks because there is some strange behavior
    if (answer !== '' && answer !== null && text !== '' && text !== null) {
      return text;
    }
    // question was answered (checkbox selected), no justification provided
    if (answer !== '' && text === '') {
      return 'No justification provided for answer.';
    }
    // 'no answer' selected (checkbox) and gave a reason
    if (answer === 'noAnswer' && text !== '') {
      return `'No answer' selected as answer. Justification provided: " + ${text}`;
    }
    // 'no answer' selected (checkbox), no justification given
    if (answer === 'noAnswer' && text === '') {
      return "'No answer' selected as answer. No justification provided.";
    }
    // question was not answered. 
    if (!answer || typeof answer === 'undefined') {
      return 'No answer provided. (Question was not answered)';
    }
  }

  getAnswerExplanation(answer, answers) {
    switch (answer) {
      case 'basic':
      case 'excellent':
      case 'strong':
      case 'weak':
        return answers[answer];
      case 'noAnswer':
        return "'No answer' selected: No explanation for this response.";
      default:
        return 'No answer provided (No corresponding justification)';
    }
  }

  renderLegend() {
    return (
      <div style={{ width: '45%', marginBottom: '50px' }}>
        <div>
          <h4>Survey Answer Legend</h4>
        </div>
        <table className="table" style={{ marginBottom: '0px' }}>
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead >
            <tr>
              <th>Weak</th>
              <th>Basic</th>
              <th>Strong</th>
              <th>Excellent</th>
              <th>No Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Radiobutton value={'weak'} checked /></td>
              <td><Radiobutton value={'basic'} checked /></td>
              <td><Radiobutton value={'strong'} checked /></td>
              <td><Radiobutton value={'excellent'} checked /></td>
              <td><Radiobutton value={'noAnswer'} checked /></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPrintButton() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <Button 
          width={'200px'}
          // onClick={() => this.onPrintClick()}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ verticalAlign: 'middle', fontSize: '14px' }}>{this.state.printBtnText}</span>
          </div>
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => this.renderPrintButton()}
          content={() => this.componentRef}
        />
        <div id="printableArea" ref={e => (this.componentRef = e)} style={{ margin: '25px' }}>
          <h1 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>{this.props.assessmentName}</h1>
          <h3 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '100px' }}>( For Internal Use Only )</h3>
          {this.renderFrChart()}
          <hr />
          {this.renderFrSurvey(this.props.assessment)}
          <hr style={{ marginTop: '0px' }} />
          {this.renderHeatmapChart()}
          <hr />
          {this.renderHeatmapSurvey(this.props.assessment)}
          <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>End of Report</p>
        </div>

      </div>
    );
  }
}

export default Report;

