import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  FaEllipsisV,
  FaAngleLeft,
} from 'react-icons/lib/fa';
import color from '../../../stylesheets/colors';

const styles = {
  first: 'folder-item rounded bg-dark d-flex justify-content-between align-items-center pl-3 pr-1 py-2',
  second: 'folder-item rounded border d-flex justify-content-between align-items-center pl-3 pr-1 py-2',
  third: 'folder-item rounded border d-flex justify-content-between align-items-center pl-3 pr-1 py-2',
};


export default class Folder extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    setVisibleHandler: PropTypes.func.isRequired,
    renameFolder: PropTypes.func.isRequired,
    addUser: PropTypes.func.isRequired,
    deleteFolder: PropTypes.func.isRequired,
    contextMenu: PropTypes.object,
    setSubmenu: PropTypes.func,
    parent: PropTypes.string,
    leftArrow: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  }

  static defaultProps = {
    contextMenu: {},
    setSubmenu: () => {},
    parent: '',
    leftArrow: false,
    isAdmin: false,
    isSuperAdmin: false,
  }

  render() {
    const {
      item,
      setVisibleHandler,
      renameFolder,
      addUser,
      deleteFolder,
      contextMenu,
      setSubmenu,
      parent,
      leftArrow,
      isSuperAdmin,
      isAdmin,
    } = this.props;

    return (
      <Fragment>
        <div
          className={styles[parent]}
          onClick={() => setSubmenu(item, parent)}
          role="presentation"
        >
          {leftArrow &&
          <span style={{ position: 'absolute', left: 0, top: 6 }}>
            <FaAngleLeft color={color.COLOR_GREY_GB} size={16} />
          </span>
          }
          <div className="text-secondary">{item.name}</div>
          {(isSuperAdmin || isAdmin) &&
          <div
            onClick={event => setVisibleHandler(event, item.id)}
            role="presentation"
          >
            <FaEllipsisV size={16} className="text-secondary" />
          </div>
          }
          <div
            className={`border context-menu ${contextMenu[item.id]
                      && 'isVisible'}`}
          >
            {parent === 'first' && isSuperAdmin &&
              <div
                className="context-menu-item text-center"
                onClick={event => renameFolder(event, item.id, item.name)}
                role="presentation"
              >
                        Rename
              </div>
            }
            {(isSuperAdmin || isAdmin) &&
              <div
                className="context-menu-item text-center"
                onClick={event => addUser(event, item, parent)}
                role="presentation"
              >
                        Add admin
              </div>
            }
            {isSuperAdmin &&
            <div
              className="context-menu-item text-center bg-danger text-white"
              onClick={event => deleteFolder(event, item.id, parent)}
              role="presentation"
            >
                      Delete
            </div>
            }
          </div>

          
        </div>
      </Fragment>
    );
  }
}
