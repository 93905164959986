import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import api from '../../services/api';
import * as actions from './actions';

function* fetchAssessments() {
  try {
    yield put(actions.fetchAssessmentsRequested());
    const response = yield call(api.default.getAssessments);
    yield put(actions.fetchAssessmentsSucceeded(response));
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.fetchAssessmentsFailed());
  }
}

function* fetchAssessment(action) {
  try {
    yield put(actions.fetchAssessmentRequested());
    const response = yield call(api.default.getAssessment, action.payload);
    yield put(actions.fetchAssessmentSucceeded(response));
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.fetchAssessmentFailed());
  }
}

function* createAssessments(action) {
  try {
    yield put(actions.createAssessmentsRequested());
    const { name, folderId } = action.payload.payload;
    const assessment = { name, folderId };
    const response = yield call(api.default.createAssessment, assessment);
    const { id } = response;
    yield put(actions.createAssessmentsSucceeded(response));
    yield action.payload.callback(id);
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.createAssessmentsFailed());
  }
}

function* submitFrs(action) {
  try {
    yield put(actions.submitFrsRequested());
    const objects = Object.values(action.payload);

    if (objects[0].data !== {}) {
      const path = objects[0].path;
      const data = Object.values(objects[0].data);
      yield call(api.default.submitFrs, data, path);
    }
    if (objects[1].data !== {}) {
      const path = objects[1].path;
      const data = Object.values(objects[1].data);
      yield call(api.default.submitFrs, data, path);
    }
    yield put(actions.submitFrsSucceeded());
    yield action.payload.callback();
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.createAssessmentsFailed());
  }
}

function* deleteAssessments(action) {
  try {
    yield call(api.default.deleteAssessment, action.payload);
    yield put(actions.deleteAssessmentSucceeded(action.payload));
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.deleteAssessmentFailed());
  }
}

function* updateAssessments(action) {
  try {
    yield call(api.default.createAssessment, action.payload);

    yield put(actions.updateAssessmentSucceeded(action.payload));
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.updateAssessmentFailed());
  }
}

function* cloneFr(action) {
  try {
    const id = { assessment: action.payload.assessmentId, fr: action.payload.frId, assessmentName: action.payload.assessmentName };
    const response = yield call(api.default.cloneFr, id);
    yield put(actions.cloneFrSucceeded({ newFr: { ...response }, assessmentId: action.payload.assessmentId }));
  } catch (ex) {
    // Posible that release does not have any release notes, and cause an error
    // yield call(handelError, ex);
    yield put(actions.cloneFrFailed());
  }
}

export function* watchCloneFr() {
  yield takeLatest(actions.cloneFrRequested.toString(), cloneFr);
}

export function* watchUpdateAssessments() {
  yield takeLatest(actions.updateAssessmentRequested.toString(), updateAssessments);
}

export function* watchdeleteAssessments() {
  yield takeLatest(actions.deleteAssessmentRequested.toString(), deleteAssessments);
}

export function* watchFetchAssessments() {
  yield takeLatest(actions.fetchAssessments.toString(), fetchAssessments);
}

export function* watchFetchAssessment() {
  yield takeLatest(actions.fetchAssessment.toString(), fetchAssessment);
}

export function* watchCreateAssessments() {
  yield takeLatest(actions.createAssessments.toString(), createAssessments);
}

export function* watchSubmitFrs() {
  yield takeLatest(actions.submitFrs.toString(), submitFrs);
}
